import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  IconButton,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Close } from '@material-ui/icons';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
  uploadImage: {
    cursor: 'pointer',
    textAlign: 'center',
    padding: '40px 0',
    border: `1px solid #999`,
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid #333`,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '45px',
    },
  },
  imgClass: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  close: {
    position: 'absolute',
    top: '10px',
    right: '20px',
  },
  snack:{
    "& .MuiAlert-action":{
      alignItems: "flex-start",
      paddingTop:"7px"
    }
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddContact = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState([]);
  const [messageState, setMessageState] = useState('');

  const [contactID, setContactID] = useState(props.activeID ? props.activeID : '');
  const [companyArr, setCompanyArr] = useState([]);
  const [loading, setLoading] = useState(false);

  const [alertData, setAlertData] = useState({
    open: true,
    success: '',
  });

  props.returnContact(alertData);

  const [contactDetails, setContactDetails] = useState({
    companyIDD: '',
    firstName: '',
    lastName: '',
    position: '',
    primaryContact: false,
    email_username: '',
    password: '',
    hasAccessToAdminPanel: false,
    contactActive: false,
    phone: '',
    mobile: '',
  });

  const handleCloseAlert = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: false,
    });
  };

  useEffect(() => {
    getSingleContact();
    getCompany();
    props.companyID != "" &&
    setContactDetails({
      ...contactDetails,
      companyIDD: props.companyID,
    });
  }, []);



  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    contactID === ''
      ? httpClient
          .post(
            '/company-contact',
            qs.stringify({
              companyID: contactDetails.companyIDD,
              firstName: contactDetails.firstName,
              lastName: contactDetails.lastName,
              position: contactDetails.position,
              primaryContact: contactDetails.primaryContact === true ? 1 : 0,
              email_username: contactDetails.email_username,
              password: contactDetails.password,
              hasAccessToAdminPanel: contactDetails.hasAccessToAdminPanel === true ? 1 : 0,
              contactActive: contactDetails.contactActive === true ? 1 : 0,
              phone: contactDetails.phone,
              mobile: contactDetails.mobile,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessageState('success');
              setMessage("Company added successfully");
              setLoading(false);
              setContactDetails({
                companyIDD: '',
                firstName: '',
                lastName: '',
                position: '',
                primaryContact: false,
                email_username: '',
                password: '',
                hasAccessToAdminPanel: false,
                contactActive: false,
                phone: '',
                mobile: '',
              });
              setTimeout(() => {
                handleCloseAlert();
              }, 1500);
            } else {
              setOpen(true);
              setMessageState('error');
              setMessage(data.message);
              setLoading(false);
            }
          })
      : httpClient
          .post(
            `/company-contact/${contactID}`,
            qs.stringify({
              companyID: contactDetails.companyIDD,
              firstName: contactDetails.firstName,
              lastName: contactDetails.lastName,
              position: contactDetails.position,
              primaryContact: contactDetails.primaryContact === true ? 1 : 0,
              email_username: contactDetails.email_username,
              password: contactDetails.password,
              hasAccessToAdminPanel: contactDetails.hasAccessToAdminPanel === true ? 1 : 0,
              contactActive: contactDetails.contactActive === true ? 1 : 0,
              phone: contactDetails.phone,
              mobile: contactDetails.mobile,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessageState('success');
              setMessage(data.message);
              setLoading(false);
              setTimeout(() => {
                handleCloseAlert();
              }, 1500);
            } else {
              setOpen(true);
              setMessageState('error');
              setMessage(data.message ? data.message : 'Failed to Edit company contact');
              setLoading(false);
            }
          });
  };

  const getCompany = () => {
    httpClient.get('company/all').then(({ data }) => {
      if (data.success === true) {
        setCompanyArr(data.data);
      }
    });
  };


  const getSingleContact = () => {
    contactID &&
      httpClient.get(`/company-contact/${contactID}`).then(({ data }) => {
        if (data.success === true) {
          setContactDetails({
            companyIDD: data.data.companyID.companyID,
            firstName: data.data.firstName,
            lastName: data.data.lastName,
            position: data.data.position,
            primaryContact: data.data.primaryContact === 1 ? true : false,
            email_username: data.data.email_username,
            // password: data.data.password,
            hasAccessToAdminPanel: data.data.hasAccessToAdminPanel === 1 ? true : false,
            contactActive: data.data.contactActive === 1 ? true : false,
            phone: data.data.phone,
            mobile: data.data.mobile,
          });
        }
      });
  };


  const handleChange = e => {
    const { name, value } = e.target;
    setContactDetails({
      ...contactDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setContactDetails({
      ...contactDetails,
      [name]: checked,
    });
  };

  return (
    <Dialog open={alertData.open} onClose={handleCloseAlert} maxWidth="md">
      <DialogTitle>
        <h2>{contactID === '' ? 'Add Contact' : 'Edit Contact'}</h2>
        <IconButton onClick={handleCloseAlert} className={classes.close}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={6}>
              <TextField
                required
                label="First Name"
                variant="outlined"
                name="firstName"
                type="text"
                value={contactDetails.firstName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                required
                label="Last Name"
                variant="outlined"
                name="lastName"
                type="text"
                value={contactDetails.lastName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Company</InputLabel>
                <Select
                  value={contactDetails.companyIDD !== undefined && contactDetails.companyIDD}
                  label="Company"
                  onChange={handleChange}
                  name="companyIDD"
                  disabled>
                  <MenuItem value="">
                    <em>Select Company</em>
                  </MenuItem>
                  {companyArr.length > 0 &&
                    companyArr.map(con => {
                      return (
                        <MenuItem key={con.companyID} value={con.companyID}>
                          {con.companyName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                label="Position"
                variant="outlined"
                name="position"
                type="text"
                value={contactDetails.position}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label={contactDetails.hasAccessToAdminPanel ? 'Email Address/User Name' : 'Email Address'}
                variant="outlined"
                name="email_username"
                type="text"
                value={contactDetails.email_username}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                label="Phone Number"
                variant="outlined"
                name="phone"
                type="text"
                value={contactDetails.phone}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                label="Mobile Number"
                variant="outlined"
                name="mobile"
                type="text"
                value={contactDetails.mobile}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="primaryContact"
                    checked={contactDetails.primaryContact}
                    onChange={handleCheck}
                    value={contactDetails.primaryContact}
                    color="primary"
                  />
                }
                label="Primary Contact?"
              />
            </Grid>
            <Grid item sm={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="hasAccessToAdminPanel"
                    checked={contactDetails.hasAccessToAdminPanel}
                    onChange={handleCheck}
                    value={contactDetails.hasAccessToAdminPanel}
                    color="primary"
                  />
                }
                label="Has Access to Admin Panel?"
              />
            </Grid>
            <Grid item sm={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="contactActive"
                    checked={contactDetails.contactActive}
                    onChange={handleCheck}
                    value={contactDetails.contactActive}
                    color="primary"
                  />
                }
                label="Contact Active?"
              />
            </Grid>

            {contactID === '' && contactDetails.hasAccessToAdminPanel === true && (
              <Grid item sm={12} md={12}>
                <TextField
                  required={contactDetails.hasAccessToAdminPanel ? true : false}
                  label="Password"
                  variant="outlined"
                  name="password"
                  type="password"
                  value={contactDetails.password}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleCloseAlert}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={loading ? true : false}>
                {contactDetails.hasAccessToAdminPanel
                  ? contactID === ''
                    ? 'Add Contact/System User'
                    : 'Edit Contact/System User'
                  : contactID === ''
                  ? 'Add Contact'
                  : 'Edit Contact'}
              </Button>
              {loading && (
                <CircularProgress size={25} style={{ marginLeft:"10px" }} />
              )}
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
        {messageState === "success" ?
            message
          : (message.length > 0 && message.map(msg => <p>{msg}</p>))
          }
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default AddContact;
