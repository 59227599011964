import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { httpClient } from 'appUtility/Api';
import { Card, CircularProgress, Grid, IconButton, makeStyles, Switch, TextField } from '@material-ui/core';
import AlertForm from '../AlertForm';
import { Close } from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertOptions from '../AlertOptions';

const qs = require('qs');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '150px',
  },
  verify: {
    float: 'right',
  },
  text: {
    width: '100%',
    margin: '10px 0',
  },
  margin: {
    marginTop: '20px',
  },
  logoImg: {
    marginLeft: '40px',
  },
  integrationGrid: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    border: '1px solid #cfcfcf',
    padding: '0 5px',
    borderRadius: '5px',
  },
  imgClass: {
    width: '100%',
    height: '40px',
    objectFit: 'contain',
  },
  imgLogo: {
    height: '175px',
    maxWidth: '175px',
    objectFit: 'contain',
  },
  close: {
    position: 'absolute',
    right: '20px',
    top: '10px',
  },
  editClick: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.primary,
    marginRight: '5px',
  },
  optionText: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.primary,
  },
  snack: {
    '& .MuiAlert-action': {
      alignItems: 'flex-start',
      paddingTop: '7px',
    },
  },
}));

const AlertIntegration = props => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState([]);
  const [messageState, setMessageState] = useState('');

  const [alertData, setAlertData] = useState({
    open: true,
    success: '',
  });

  const [openIntDialog, setOpenIntDialog] = useState(false);
  const [openOptionDialog, setOpenOptionDialog] = useState(false);

  const [dataSource, setDataSource] = useState({});
  const [integrations, setIntegrations] = useState([]);
  const [integrationID, setIntegrationID] = useState('');
  const [edit, setEdit] = useState(false);
  const [integrationCheck, setIntegrationCheck] = useState(false);
  const [intCheck, setIntCheck] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDataSource();
    getCompanyIntegrations();
  }, [props.companyData.companyDataSourceType]);

  const getCompanyIntegrations = () => {
    dataSource &&
      props.companyData &&
      props.companyData.companyID !== undefined &&
      httpClient
        .get(`/integration-type/isintegrated/${props.companyData && props.companyData.companyID}`)
        .then(({ data }) => {
          // httpClient.get(`/integration-type/all`).then(({ data }) => {
          if (data.success === true) {
            setIntegrations(data.data);
          } else {
          }
        });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getDataSource = () => {
    setLoading(true);
    props.companyData &&
      httpClient.get('data/source-type').then(({ data }) => {
        if (data.success === true) {
          data.data.map(com => {
            com.dataSourceTypeID ==
              (props.companyData &&
                props.companyData.companyDataSourceType &&
                props.companyData.companyDataSourceType.companyDataSourceTypeID) && setDataSource(com);
          });
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  const handleOffChange = (e, id) => {
    // const {name, checked} = e.target;
    if (!integrationCheck) setIntegrationCheck(true);
    else setIntegrationCheck(false);
    setIntegrationID(id);

    httpClient.get(`/integration/activate-deactivate/${id}`).then(({ data }) => {
      if (data.success) {
        setOpen(true);
        setMessageState('success');
        setMessage(data.message);
        getCompanyIntegrations();
      } else {
        setOpen(true);
        setMessageState('error');
        setMessage(data.message);
      }
    });
  };

  const handleChange = id => {
    setOpenIntDialog(true);
    setIntegrationID(id);
  };

  const handleEditIntegration = id => {
    setOpenIntDialog(true);
    setIntegrationID(id);
    setEdit(true);
  };

  const sendVerifyData = child => {
    child.alert.open === false && setOpenIntDialog(false);
    child.alert.open === false && setEdit(false);

    // console.log('childData', child);
    if (child.alert.success === true) {
      httpClient
        .post(
          `/integration/${props.companyData && props.companyData.companyID}`,
          qs.stringify({
            integrationID: child.integration.integrationType,
            compIntegrationAPIUrl: child.integration.apiURL,
            compIntegrationUserName: child.integration.username,
            compIntegrationPassword: child.integration.password,
            compIntegrationUDF1Value: child.integration.udf1Value,
            compIntegrationUDF2Value: child.integration.udf2Value,
            compIntegrationUDF3Value: child.integration.udf3Value,
            compIntegrationUDF4Value: child.integration.udf4Value,
            compIntegrationUDF5Value: child.integration.udf5Value,
            compIntegrationUDF6Value: child.integration.udf6Value,
            compIntegrationUDF7Value: child.integration.udf7Value,
            compIntegrationUDF8Value: child.integration.udf8Value,
            compIntegrationUDF9Value: child.integration.udf9Value,
            compIntegrationUDF10Value: child.integration.udf10Value,
            compIntegrationNotes: child.integration.notes,
            compIntegrationHelpURL: child.integration.helpURL,
            compIntegrationActive: child.integration.active == true ? 1 : 0,
          }),
        )
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState('success');
            setMessage(data.message);
            getCompanyIntegrations();
          } else {
            setOpen(true);
            setMessageState('error');
            setMessage(data.message);
          }
        });
    }
  };

  // console.log('data source company >', dataSource);
  // console.log('company data', props.companyData.companyID);

  // console.log('props>>>', props);

  const Snack = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: false,
    });
  };

  const handleVerifySource = () => {
    console.log('verify data source');
  };

  const handleOptions = id => {
    setOpenOptionDialog(true);
    setIntegrationID(id);
  };
  const sendOptions = child => {
    console.log('child options', child);
    child.open == false && setOpenOptionDialog(false);
  };

  props.sendIntegrationData(alertData);

  return (
    <Dialog open={alertData.open} onClose={Snack} fullWidth={true} maxWidth={'lg'}>
      {!loading ? (
        <div>
          <IconButton onClick={Snack} className={classes.close}>
            <Close />
          </IconButton>
          <DialogTitle>
            <h2>{props.companyData.companyName} Integration</h2>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item sm={12} md={6}>
                <Card className={classes.root}>
                  <h3>Data Source</h3>
                  <div>
                    <div className={classes.margin}>
                      <div>
                        <h5>Name</h5>
                      </div>
                      <TextField
                        value={dataSource.dataSourceTypeName}
                        disabled={true}
                        variant="outlined"
                        className={classes.text}
                      />
                    </div>
                    <div>
                      <div>
                        <h5>Logo</h5>
                      </div>
                      <div className={classes.logoImg}>
                        <img
                          src={dataSource.dataSourceTypeLogo}
                          alt={dataSource.dataSourceTypeName}
                          className={classes.imgLogo}
                        />
                      </div>
                    </div>
                    <div className={classes.verify}>
                      <Button color="primary" variant="contained" onClick={handleVerifySource}>
                        Verify
                      </Button>
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item sm={12} md={6}>
                <Card className={classes.root}>
                  <h3>Integrated With {props.companyData.companyName}</h3>
                  {integrations &&
                    integrations.map(integ => {
                      return (
                        <Grid container spacing={2} className={classes.integrationGrid}>
                          <Grid item sm={6}>
                            {integ.integrationName}
                          </Grid>
                          <Grid item sm={2}>
                            <img src={integ.integrationImage} className={classes.imgClass} />
                          </Grid>
                          <Grid item sm={4}>
                            {integ.isIntegratedBefore === 'Yes' ? (
                              integ.isIntegrated === 'Yes' ? (
                                <Switch
                                  checked={true}
                                  onChange={e =>
                                    handleOffChange(e, integ.compIntegration && integ.compIntegration.compIntegrationID)
                                  }
                                />
                              ) : (
                                <Switch
                                  checked={false}
                                  onChange={e =>
                                    handleOffChange(e, integ.compIntegration && integ.compIntegration.compIntegrationID)
                                  }
                                />
                              )
                            ) : (
                              <Switch checked={false} onChange={() => handleChange(integ.integrationID)} />
                            )}
                            {integ.isIntegratedBefore === 'Yes' && (
                              <a
                                onClick={() =>
                                  handleEditIntegration(
                                    // integ.compIntegration &&
                                    //   integ.compIntegration.map(comInteg => {
                                    //     if (comInteg.companyID === props.companyData.companyID) {
                                    //       return comInteg.compIntegrationID;
                                    //     }
                                    //   })
                                    integ.compIntegration && integ.compIntegration.compIntegrationID,
                                  )
                                }
                                className={classes.editClick}>
                                Edit
                              </a>
                            )}
                            <a onClick={() => handleOptions(integ.integrationID)} className={classes.optionText}>
                              Options
                            </a>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={Snack} color="primary">
              Cancel
            </Button>
            {/* <Button onClick={handleDisable} color="secondary" autoFocus>
          Yes
        </Button> */}
          </DialogActions>
        </div>
      ) : (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}

      {openIntDialog && <AlertForm sendVerifyData={sendVerifyData} integrationID={integrationID} edit={edit} />}

      {openOptionDialog && (
        <AlertOptions
          sendOptions={sendOptions}
          integrationID={integrationID}
          companyID={props.companyData && props.companyData.companyID}
        />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={messageState}>
          {messageState === 'success' ? message : message.length > 0 && message.map(msg => <p>{msg}</p>)}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default AlertIntegration;
