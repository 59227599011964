import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import { select } from '@storybook/addon-knobs';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import AlertDelete from '../AlertDelete';
import AlertDialog from '../AlertDialog';
import TableDeletedComponent from './../TableDeletedComponent';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertRestore from '../AlertRestore';

const columns = [
  { id: 'dataSourceTypeName', label: 'Name' },
  { id: 'dataSourceTypeLogo', label: 'Logo' },
  { id: 'dataSourceTypeHelpLink', label: 'Documentation URL' },
  { id: 'dataSourceTypeHelpNotes', label: 'Integration Notes' },
  // { id: 'dataSourceTypeAdded', label: 'Added Date' },
  { id: 'dataSourceTypeActive', label: 'Active' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ListDeletedDataSource = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);

  const [selectID, setSelectID] = useState('');
//   const [activeStatus, setActiveStatus] = useState('');
//   const [activateTable, setActivateTable] = useState('');
  const [productData, setProductData] = useState([]);
  const [deleteData, setDeleteData] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

//   const [submittedData, setSubmittedData] = useState({
//     submit: false,
//     active: '',
//     search: '',
//   });
//   const [filterData, setFilterData] = useState({
//     active: '',
//     search: '',
//     removed: '',
//   });

//   useEffect(() => {
//     if (filterData.active === '' && filterData.state === '' && filterData.search === '') {
//       setSubmittedData({
//         ...submittedData,
//         submit: false,
//       });
//     }
//     if (filterData.active === ' ') filterData.active = '';
//     if (filterData.search === ' ') filterData.search = '';

//     filterData.removed === true && handleSubmitData();
//   }, [filterData]);

  useEffect(() => {
    getDataSource();
  }, []);

//   useEffect(() => {
//     let stateStorage = JSON.parse(localStorage.getItem('dataSource_filter'));
//     stateStorage !== null && setFilterData(stateStorage);

//     stateStorage == null
//       ? getDataSource()
//       : stateStorage.active == '' && stateStorage.search == '' && stateStorage.removed == false
//       ? getDataSource()
//       : console.log('state');
//   }, []);

//   const handleSubmitData = () => {
//     setSubmittedData({
//       active: filterData.active,
//       search: filterData.search,
//       submit: true,
//     });
//     filterData.removed = true;
//     localStorage.setItem('dataSource_filter', JSON.stringify(filterData));
//     setLoading(true);
//     if (filterData.active || filterData.search) {
//       httpClient.get(`state/filter?q=${filterData.search}&dataSourceTypeActive=${filterData.active}`).then(({ data }) => {
//         if (data.success === true) {
//           setDataSource(data.data);
//           setTotal(data.meta.total);
//           // setRowsPerPage(data.meta.per_page);
//           setPage(data.meta.current_page);
//           setFrom(data.meta.from);
//           setTo(data.meta.to);
//           setLoading(false);
//         }
//       });
//     } else {
//       getDataSource();
//     }
//   };

//   const handleFilter = e => {
//     e.preventDefault();
//     handleSubmitData();
//   };

  const getDataSource = () => {
    setLoading(true);
    httpClient.get(`data/source-type/deleted-data-source-type?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setDataSource(data.data);
        setTotal(data.meta.total);
        // setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

//   const handleChange = e => {
//     const { name, value } = e.target;
//     setFilterData({
//       ...filterData,
//       [name]: value,
//       removed: false,
//     });
//   };

//   const handleRemove = data => {
//     setFilterData({
//       ...filterData,
//       [data]: '',
//       removed: true,
//     });
//     setSubmittedData({
//       ...submittedData,
//       [data]: '',
//     });
//   };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    // console.log('direction', direction);
    setCurrentColumn(column);
    httpClient
      .get(`data/source-type/deleted-data-source-type?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setDataSource(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    // submittedData.submit
    //   ? httpClient
    //       .get(
    //         `data/source-type/deleted-data-source-type/filter?q=${filterData.search}&dataSourceType=${filterData.active}&pagination=${rowsPerPage}&page=${page}`,
    //       )
    //       .then(({ data }) => {
    //         if (data.success === true) {
    //           setDataSource(data.data);
    //           setTotal(data.meta.total);
    //           setRowsPerPage(parseInt(data.meta.per_page));
    //           setPage(data.meta.current_page);
    //           setFrom(data.meta.from);
    //           setTo(data.meta.to);
    //           setLoading(false);
    //         }
    //       })
    //   : 
      httpClient
          .get(
            `data/source-type/deleted-data-source-type?direction=desc&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setDataSource(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    // localStorage.setItem('configRowPerPage', event.target.value);
    // submittedData.submit
    //   ? httpClient
    //       .get(
    //         `data/source-type/deleted-data-source-type/filter?q=${filterData.search}&dataSourceTypeActive=${filterData.active}&pagination=${+event
    //           .target.value}&page=${1}`,
    //       )
    //       .then(({ data }) => {
    //         setLoading(true);
    //         if (data.success === true) {
    //           setDataSource(data.data);
    //           setTotal(data.meta.total);
    //           setRowsPerPage(parseInt(data.meta.per_page));
    //           setFrom(data.meta.from);
    //           setTo(data.meta.to);
    //           setPage(data.meta.current_page);
    //           setLoading(false);
    //         }
    //       })
    //   : 
      httpClient
          .get(
            `data/source-type/deleted-data-source-type?direction=desc&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setDataSource(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          });
  };

//   const handleEdit = val => {
//     history.push(`/dataSource/addDataSource/${val.memID}`);
//   };

//   const handleActivate = val => {
//     setOpenDialog(true);
//     setSelectID(val.memID);
//     setActiveStatus('activate');
//   };

//   const handleDeactivate = val => {
//     setOpenDialog(true);
//     setSelectID(val.memID);
//     setActiveStatus('Deactivate');
//   };

//   const sendDeactivate = child => {
//     child.open == false && setOpenDialog(false);

//     if (child.success === true) {
//       httpClient.post(`/data/source-type/deleted-data-source-type/activate-deactivate/${selectID}`).then(({ data }) => {
//         if (data.success) {
//           setOpen(true);
//           setMessage(data.message);
//           setMessageState('success');
//           // setTimeout(() => {
//           //   window.location.reload();
//           // }, 1500);
//           // activeStatus === "Activate" ? setActivateTable("handleActivate") : setActivateTable("handleDeactivate");
//           // getDataSource();
//           setProductData(data.data);
//         } else {
//           setOpen(true);
//           setMessage('Failed to Update the Data Source');
//           setMessageState('error');
//         }
//       });
//     }
//   };

  const handleRestore = val => {
    setSelectID(val.memID);
    setOpenRestoreDialog(true);
  };

  const sendRestore = child => {
    child.open === false && setOpenRestoreDialog(false);
    if (child.success === true) {
      httpClient.delete(`data/source-type/restore/${selectID}`).then(({ data }) => {
        if (data.success === true) {
          setOpen(true);
          setMessage(data.message);
          setMessageState('success');
          setProductData(data.data);
          getDataSource();

        } else {
          setOpen(true);
          setMessage('Failed to Restore the Data Source');
          setMessageState('error');
        }
      });
    }
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getstates();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`state/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setDataSource(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="Data Source">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableDeletedComponent
              columns={columns}
              selectedProducts={dataSource}
              title="List Data Source"
            //   handleEdit={handleEdit}
            //   handleActivate={handleActivate}
            //   handleDeactivate={handleDeactivate}
              handleRestore={handleRestore}

              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
            //   handleFilter={handleFilter}
            //   filterData={filterData}
            //   submittedData={submittedData}
            //   handleChange={handleChange}
            //   handleRemove={handleRemove}
              direction={direction}
              currentColumn={currentColumn}
            //   activateTable={activateTable}
              productData={productData}
              deleteData={deleteData}
            />
          </Box>
        </Grid>
      </GridContainer>

      {openRestoreDialog && <AlertRestore sendRestore={sendRestore} />}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default ListDeletedDataSource;
