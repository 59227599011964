import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { history } from 'redux/store';
const qs = require('qs');

const useStyles = makeStyles({
  text: {
    width: '100%',
    margin: '5px',
  },
  root: {
    padding: '40px',
  },
  snack:{
    "& .MuiAlert-action":{
      alignItems: "flex-start",
      paddingTop:"7px"
    }
  }
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddState = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState([]);
  const [messageState, setMessageState] = useState('');
  const [stateID, setStateID] = useState(props && props.location.pathname.slice(18));
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);


  const [stateDetails, setStateDetails] = useState({
    stateName: '',
    stateShortName: '',
    countryID: '',
    stateActive: false,
  });

  useEffect(() => {
    getSingleState();
    getCountries();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setStateDetails({
      ...stateDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setStateDetails({
      ...stateDetails,
      [name]: checked,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    stateID === ''
      ? httpClient
          .post(
            'state',
            qs.stringify({
              stateName: stateDetails.stateName,
              stateShortName: stateDetails.stateShortName,
              stateActive: stateDetails.stateActive === true ? 1 : 0,
              countryID: stateDetails.countryID,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setLoading(false);
              setOpen(true);
              setMessageState('success');
              setMessage(data.message);
              setStateDetails({
                stateName: '',
                stateShortName: '',
                countryID: '',
                stateActive: false,
              });
            } else {
              setLoading(false);
              setOpen(true);
              setMessageState('error');
              setMessage(data.message);
            }
          })
      : httpClient
          .post(
            `state/${stateID}`,
            qs.stringify({
              stateName: stateDetails.stateName,
              stateShortName: stateDetails.stateShortName,
              stateActive: stateDetails.stateActive === true ? 1 : 0,
              countryID: stateDetails.countryID,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setLoading(false);
              setOpen(true);
              setMessageState('success');
              setMessage(data.message);
              history.push('/country/listState');
            } else {
              setLoading(false);
              setOpen(true);
              setMessageState('error');
              setMessage(data.message);
            }
          });
  };

  const getSingleState = () => {
    stateID &&
      httpClient.get(`/state/${stateID}`).then(({ data }) => {
        if (data.success === true) {
          setStateDetails({
            stateName: data.data.stateName,
            stateActive: data.data.stateActive === 'Active' ? true : false,
            stateShortName: data.data.stateShortName,
            countryID: data.data.country.countryID,
          });
        }
      });
  };

  const getCountries = () => {
    httpClient.get(`/country/all`).then(({ data }) => {
      setCountries(data.data);
    });
  };

  const handleBack = () => {
    history.push('/country/listState');
  };

  return (
    <PageContainer heading={stateID === '' ? 'Add State' : 'Edit State'}>
      <Card className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="State Name"
                variant="outlined"
                name="stateName"
                value={stateDetails.stateName}
                // error={countryError}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                label="State Short Name"
                variant="outlined"
                name="stateShortName"
                value={stateDetails.stateShortName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl fullWidth variant="outlined" className={classes.text}>
                <InputLabel>Country</InputLabel>
                <Select value={stateDetails.countryID} name="countryID" label="Country" onChange={handleChange}>
                  <MenuItem value="">
                    <em>Select Country</em>
                  </MenuItem>
                  {countries &&
                    countries.map(con => {
                      return (
                        <MenuItem key={con.countryID} value={con.countryID}>
                          {con.countryName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="stateActive"
                    checked={stateDetails.stateActive}
                    onChange={handleCheck}
                    value={stateDetails.stateActive}
                    color="primary"
                  />
                }
                label="Make State Active"
              />
            </Grid>
            <Grid item sm={12}>
              <Box textAlign="right" pt={4}>
                <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit" disabled={loading ? true : false}>
                  {stateID === '' ? 'Add State' : 'Edit State'}
                </Button>
                {loading && <CircularProgress size={25} />}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
        {messageState === "success" ?
            message
          : (message.length > 0 && message.map(msg => <p>{msg}</p>))
          }
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddState;
