import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddCountry from './AddCountry';
import AddState from './AddState';
import ListCountry from './ListCountry';
import ListState from './ListState';

const Country = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listCountry`} />
        <Route path={`${requestedUrl}/addCountry`} component={AddCountry} />
        <Route path={`${requestedUrl}/listCountry`} component={ListCountry} />
        <Route path={`${requestedUrl}/listState`} component={ListState} />
        <Route path={`${requestedUrl}/addState`} component={AddState} />

        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default Country;
