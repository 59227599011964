import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import { select } from '@storybook/addon-knobs';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
// import AlertDelete from '../AlertDelete';
import AlertDialog from '../AlertDialog';
import TableComponent from './../TableComponent';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const columns = [
  { id: 'syncFieldSettingID', label: 'ID' },
  { id: 'syncFieldSettingCode', label: 'Setting Code' },
  { id: 'syncFieldSettingName', label: 'Setting Name' },
  { id: 'syncFieldSettingGroupName', label: 'Setting Group' },
  { id: 'syncFieldSettingDescription', label: 'Setting Description' },
  { id: 'syncFieldSettingAddedDate', label: 'Added Date' },
  { id: 'syncFieldSettingActive', label: 'Active' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ListSyncFields = () => {
  const [syncFields, setSyncFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [selectID, setSelectID] = useState('');
  const [activeStatus, setActiveStatus] = useState('');
  const [activateTable, setActivateTable] = useState('');
  const [productData, setProductData] = useState([]);
  const [deleteData, setDeleteData] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    settingCode: '',
    settingName: '',
    settingGroup: '',
    settingActive: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    settingCode: '',
    settingName: '',
    settingGroup: '',
    settingActive: '',
    search: '',
    removed: '',
  });

  useEffect(() => {
    if (
      filterData.settingCode === '' &&
      filterData.settingName === '' &&
      filterData.settingGroup === '' &&
      filterData.settingActive === '' &&
      filterData.search === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.settingCode === ' ') filterData.settingCode = '';
    if (filterData.settingName === ' ') filterData.settingName = '';
    if (filterData.settingGroup === ' ') filterData.settingGroup = '';
    if (filterData.settingActive === ' ') filterData.settingActive = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  // useEffect(() => {
  //   getSyncFields();
  // }, []);

  useEffect(() => {
    let stateStorage = JSON.parse(localStorage.getItem('syncFields_filter'));
    stateStorage !== null && setFilterData(stateStorage);

    stateStorage == null
      ? getSyncFields()
      : stateStorage.settingName == '' &&
        stateStorage.settingCode == '' &&
        stateStorage.settingGroup == '' &&
        stateStorage.settingActive == '' &&
        stateStorage.search == '' &&
        stateStorage.removed == false
      ? getSyncFields()
      : console.log('sync');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      settingCode: filterData.settingCode,
      settingName: filterData.settingName,
      settingGroup: filterData.settingGroup,
      settingActive: filterData.settingActive,
      search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    localStorage.setItem('syncFields_filter', JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.search ||
      filterData.settingCode ||
      filterData.settingName ||
      filterData.settingGroup ||
      filterData.settingActive
    ) {
      httpClient
        .get(
          `field-setting/filter?q=${filterData.search}&syncFieldSettingCode=${filterData.settingCode}&syncFieldSettingName=${filterData.settingName}&syncFieldSettingGroupName=${filterData.settingGroup}&syncFieldSettingActive=${filterData.settingActive}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setSyncFields(data.data);
            setTotal(data.meta.total);
            // setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getSyncFields();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const getSyncFields = () => {
    setLoading(true);
    httpClient.get(`field-setting?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setSyncFields(data.data);
        setTotal(data.meta.total);
        // setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    // console.log('direction', direction);
    setCurrentColumn(column);
    httpClient
      .get(`field-setting?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setSyncFields(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `field-setting/filter?q=${filterData.search}&syncFieldSettingCode=${filterData.settingCode}&syncFieldSettingName=${filterData.settingName}&syncFieldSettingGroupName=${filterData.settingGroup}&syncFieldSettingActive=${filterData.settingActive}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setSyncFields(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`field-setting?direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setSyncFields(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `field-setting/filter?q=${filterData.search}&syncFieldSettingCode=${
              filterData.settingCode
            }&syncFieldSettingName=${filterData.settingName}&syncFieldSettingGroupName=${
              filterData.settingGroup
            }&syncFieldSettingActive=${filterData.settingActive}&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setSyncFields(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          })
      : httpClient.get(`field-setting?direction=desc&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setSyncFields(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setPage(data.meta.current_page);
            setLoading(false);
          }
        });
  };

  const handleEdit = val => {
    history.push(`/sync/addSyncFields/${val.memID}`);
  };

  const handleActivate = val => {
    setOpenDialog(true);
    setSelectID(val.memID);
    setActiveStatus('activate');
  };

  const handleDeactivate = val => {
    setOpenDialog(true);
    setSelectID(val.memID);
    setActiveStatus('Deactivate');
  };

  const sendDeactivate = child => {
    child.open == false && setOpenDialog(false);

    if (child.success === true) {
      httpClient.get(`/field-setting/activate-deactivate/${selectID}`).then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessage(data.message);
          setMessageState('success');
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1500);
          // activeStatus === "Activate" ? setActivateTable("handleActivate") : setActivateTable("handleDeactivate");
          // getSyncFields();
          setProductData(data.data);
        } else {
          setOpen(true);
          setMessage('Failed to Update the Data Source');
          setMessageState('error');
        }
      });
    }
  };

  // const handleDelete = val => {
  //   setSelectID(val.memID);
  //   setOpenDeleteDialog(true);
  // };

  // const sendDelete = child => {
  //   child.open === false && setOpenDeleteDialog(false);
  //   if (child.success === true) {
  //     httpClient.delete(`/field-setting/${selectID}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setOpen(true);
  //         setMessage(data.message);
  //         setMessageState('success');
  //         setDeleteData(data.data);
  //         getSyncFields();
  //       } else {
  //         setOpen(true);
  //         setMessage('Failed to Delete the Data Source');
  //         setMessageState('error');
  //       }
  //     });
  //   }
  // };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getstates();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`state/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setSyncFields(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="Sync Fields">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableComponent
              columns={columns}
              selectedProducts={syncFields}
              title="List Sync Fields"
              handleEdit={handleEdit}
              handleActivate={handleActivate}
              handleDeactivate={handleDeactivate}
              // handleDelete={handleDelete}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleRemove={handleRemove}
              direction={direction}
              currentColumn={currentColumn}
              activateTable={activateTable}
              productData={productData}
              // deleteData={deleteData}
            />
          </Box>
        </Grid>
      </GridContainer>
      {openDialog && <AlertDialog sendDeactivate={sendDeactivate} activeStatus={activeStatus} />}

      {/* {openDeleteDialog && <AlertDelete sendDelete={sendDelete} />} */}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default ListSyncFields;
