import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
import AddContact from '../AddContact';
import AlertDialogCompany from '../AlertDialogCompany';
import AlertIntegration from '../AlertIntegration';
import ListContact from '../ListContact';
import TableComponent from './../TableComponent';
import AlertModuleIntegration from '../AlertModuleIntegration';

const columns = [
  { id: 'companyName', label: 'company Name' },
  { id: 'companyDataSourceTypeID', label: 'Data Source' },
  // { id: 'companyDataSourceUserName', label: 'Data Source Username' },
  { id: 'companyAddress', label: 'Address' },
  { id: 'companyWebsite', label: 'Website' },
  { id: 'companyActive', label: 'Active' },
  { id: 'actions', label: 'Actions' },
];

// const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ListCompany = () => {
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [companyData, setCompanyData] = useState({});
  const [openIntegrationDialog, setOpenIntegrationDialog] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [activeStatus, setActiveStatus] = useState('');
  const [activeTable, setActiveTable] = useState('');
  const [productData, setProductData] = useState({});

  const [openModulesDialog, setOpenModulesDialog] = useState(false);

  const [companyID, setCompanyID] = useState('');
  const [openAddContactDialog, setOpenAddContactDialog] = useState(false);
  const [openViewContactDialog, setOpenViewContactDialog] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    // configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
    20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(20);
  //   const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);
  const pageURL = window.location.hostname === 'localhost' ? 'http://localhost:3000/' : 'https://dev.synccare.com.au';

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  //   const [submittedData, setSubmittedData] = useState({
  //     submit: false,
  //     companyActive: '',
  //     state: '',
  //     search: '',
  //   });
  //   const [filterData, setFilterData] = useState({
  //     companyActive: '',
  //     state: '',
  //     search: '',
  //     removed: '',
  //   });

  //   useEffect(() => {
  //     if (filterData.companyActive === '' && filterData.state === '' && filterData.search === '') {
  //       setSubmittedData({
  //         ...submittedData,
  //         submit: false,
  //       });
  //     }
  //     if (filterData.companyActive === ' ') filterData.companyActive = '';
  //     if (filterData.state === ' ') filterData.state = '';
  //     if (filterData.search === ' ') filterData.search = '';

  //     filterData.removed === true && handleSubmitData();
  //   }, [filterData]);

  useEffect(() => {
    getCompanies();
  }, []);

  //   useEffect(() => {
  //     let companyStorage = JSON.parse(localStorage.getItem('company_filter'));
  //     companyStorage !== null && setFilterData(companyStorage);

  //     companyStorage == null
  //       ? getcompanys()
  //       : companyStorage.companyActive == '' &&
  //         companyStorage.state == '' &&
  //         companyStorage.search == '' &&
  //         companyStorage.removed == false
  //       ? getcompanys()
  //       : console.log('company');
  //   }, []);

  //   const handleSubmitData = () => {
  //     setSubmittedData({
  //       companyActive: filterData.companyActive,
  //       state: filterData.state,
  //       search: filterData.search,
  //       submit: true,
  //     });
  //     filterData.removed = true;
  //     localStorage.setItem('company_filter', JSON.stringify(filterData));
  //     setLoading(true);
  //     if (filterData.companyActive || filterData.state || filterData.search) {
  //       httpClient
  //         .get(`company/filter?q=${filterData.search}&companyActive=${filterData.companyActive}&state=${filterData.state}`)
  //         .then(({ data }) => {
  //           if (data.success === true) {
  //             setCompany(data.data);
  //             setTotal(data.meta.total);
  //             // setRowsPerPage(data.meta.per_page);
  //             setPage(data.meta.current_page);
  //             setFrom(data.meta.from);
  //             setTo(data.meta.to);
  //             setLoading(false);
  //           }
  //         });
  //     } else {
  //       getcompanys();
  //     }
  //   };

  //   const handleFilter = e => {
  //     e.preventDefault();
  //     handleSubmitData();
  //   };

  const getCompanies = () => {
    setLoading(true);
    httpClient.get(`company?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setCompany(data.data);
        // sessionStorage.setItem("company_details",JSON.stringify(data.data));
        setTotal(data.meta.total);
        // setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  //   const handleChange = e => {
  //     const { name, value } = e.target;
  //     setFilterData({
  //       ...filterData,
  //       [name]: value,
  //       removed: false,
  //     });
  //   };

  //   const handleRemove = data => {
  //     setFilterData({
  //       ...filterData,
  //       [data]: '',
  //       removed: true,
  //     });
  //     setSubmittedData({
  //       ...submittedData,
  //       [data]: '',
  //     });
  //   };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    // console.log('direction', direction);
    setCurrentColumn(column);
    httpClient
      .get(`company?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setCompany(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    // submittedData.submit
    //   ? httpClient
    //       .get(
    //         `company/filter?q=${filterData.search}&companyActive=${filterData.companyActive}&state=${filterData.state}&pagination=${rowsPerPage}&page=${page}`,
    //       )
    //       .then(({ data }) => {
    //         if (data.success === true) {
    //           setCompany(data.data);
    //           setTotal(data.meta.total);
    //           setRowsPerPage(parseInt(data.meta.per_page));
    //           setPage(data.meta.current_page);
    //           setFrom(data.meta.from);
    //           setTo(data.meta.to);
    //           setLoading(false);
    //         }
    //       })
    //   :

    httpClient.get(`company?direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
      if (data.success === true) {
        setCompany(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    // localStorage.setItem('configRowPerPage', event.target.value);
    // submittedData.submit
    //   ? httpClient
    //       .get(
    //         `company/filter?q=${filterData.search}&companyActive=${filterData.companyActive}&state=${
    //           filterData.state
    //         }&pagination=${+event.target.value}&page=${1}`,
    //       )
    //       .then(({ data }) => {
    //         setLoading(true);
    //         if (data.success === true) {
    //           setCompany(data.data);
    //           setTotal(data.meta.total);
    //           setRowsPerPage(parseInt(data.meta.per_page));
    //           setFrom(data.meta.from);
    //           setTo(data.meta.to);
    //           setPage(data.meta.current_page);
    //           setLoading(false);
    //         }
    //       })
    //   :

    httpClient.get(`company?direction=desc&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
      setLoading(true);
      if (data.success === true) {
        setCompany(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(parseInt(data.meta.per_page));
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setPage(data.meta.current_page);
        setLoading(false);
      }
    });
  };

  const handleEdit = val => {
    // console.log('val', val);
    history.push(`/company/addCompany/${val.memID}`);
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getcompanys();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`company/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setCompany(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  const handleViewContact = val => {
    // history.push(`/company/listContact/${val.memID}`);
    setOpenViewContactDialog(true);
    setCompanyID(val.memID);
  };

  const handleAddContact = val => {
    // history.push(`/company/addContact`,{ data: val.memID })
    setOpenAddContactDialog(true);
    setCompanyID(val.memID);
  };

  const returnViewData = call => {
    call.open === false && setOpenViewContactDialog(false);
  };

  const returnContact = callback => {
    callback.open === false && setOpenAddContactDialog(false);
  };

  const handleBrowse = val => {
    httpClient.post(`/login/company/${val.memID}`).then(({ data }) => {
      if (data.success) {
        sessionStorage.setItem('login_company', JSON.stringify(data.data));
        sessionStorage.setItem('companyToken', JSON.stringify(data.data.access_token));
        window.open(pageURL, '_blank').focus();
        // history.push("/dashboard");
      } else {
      }
    });
  };

  const handleIntegration = val => {
    httpClient.get('company').then(({ data }) => {
      if (data.success === true) {
        data.data.map(com => {
          if (com.companyID === val.memID) {
            setCompanyData(com);
          }
        });
      }
    });

    setOpenIntegrationDialog(true);
  };

  const sendIntegrationData = child => {
    child.open === false && setOpenIntegrationDialog(false);
    child.open === false && setCompanyData({});
  };

  const handleActivate = val => {
    setOpenAlertDialog(true);
    setCompanyID(val.memID);
    setActiveStatus('Activate');
  };

  const handleModuleIntegration = val => {
    httpClient.get('company').then(({ data }) => {
      if (data.success === true) {
        data.data.map(com => {
          if (com.companyID === val.memID) {
            setCompanyData(com);
          }
        });
      }
    });
    setOpenModulesDialog(true);
  };

  const sendModuleIntegrationData = child => {
    child.open === false && setOpenModulesDialog(false);
    child.open === false && setCompanyData({});
  };

  const handleDeactivate = val => {
    setOpenAlertDialog(true);
    setCompanyID(val.memID);
    setActiveStatus('Deactivate');
  };

  const sendDataCompany = call => {
    call.open === false && setOpenAlertDialog(false);
    // call.open === false && setProductData({});
    if (call.success === true) {
      httpClient.get(`/company/activate-deactivate/${companyID}`).then(({ data }) => {
        if (data.success === true) {
          setOpen(true);
          setMessage(data.message);
          setMessageState('success');
          // activeStatus === "Activate" ? setActiveTable("handleActivate") : setActiveTable("handleDeactivate");
          setProductData(data.data);
          // getCompanies();
        } else {
          setOpen(true);
          setMessage('Failed to Update Company');
          setMessageState('error');
        }
      });
    }
  };

  return (
    <PageContainer heading="Company">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableComponent
              columns={columns}
              selectedProducts={company}
              title="List Company"
              handleEdit={handleEdit}
              handleViewContact={handleViewContact}
              handleAddContact={handleAddContact}
              handleBrowse={handleBrowse}
              handleIntegration={handleIntegration}
              handleModuleIntegration={handleModuleIntegration}
              handleDeactivate={handleDeactivate}
              handleActivate={handleActivate}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              //   handleFilter={handleFilter}
              //   filterData={filterData}
              //   submittedData={submittedData}
              //   handleChange={handleChange}
              //   handleRemove={handleRemove}
              direction={direction}
              currentColumn={currentColumn}
              // activeTable={activeTable}
              productData={productData}
            />
          </Box>
        </Grid>
      </GridContainer>

      {openIntegrationDialog && <AlertIntegration sendIntegrationData={sendIntegrationData} companyData={companyData} />}

      {openModulesDialog && (
        <AlertModuleIntegration sendModuleIntegrationData={sendModuleIntegrationData} companyData={companyData} />
      )}

      {openAddContactDialog && <AddContact companyID={companyID} returnContact={returnContact} />}

      {openViewContactDialog && <ListContact companyID={companyID} returnViewData={returnViewData} />}

      {openAlertDialog && <AlertDialogCompany sendDataCompany={sendDataCompany} activeStatus={activeStatus} />}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default ListCompany;
