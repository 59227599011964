import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AlertDialog = props => {
  const [alertData, setAlertData] = useState({
    open: true,
    success: '',
  });

  const handleClose = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: false,
    });
  };

  const handleDeactivate = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: true,
    });
  };

  props.sendDeactivate(alertData);

  return (
    <Dialog open={alertData.open} onClose={handleClose}>
      <DialogTitle>{props.activeStatus == 'Deactivate' ? 'Deactive User' : 'Activate User'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.activeStatus == 'Deactivate'
            ? 'Are you sure, you want to De-Activate this user?'
            : 'Are you sure you want to Activate the user ?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button onClick={handleDeactivate} color="secondary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
