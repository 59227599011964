import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AlertRestore = props => {
  const [alertData, setAlertData] = useState({
    open: true,
    success: '',
  });

  const handleClose = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: false,
    });
  };

  const handleRestored = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: true,
    });
  };

  props.sendRestore(alertData);

  return (
    <Dialog open={alertData.open} onClose={handleClose}>
      <DialogTitle>Re-List Data Source</DialogTitle>
      <DialogContent>
        <DialogContentText>
        Are you sure, you want to Re-list this data source
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button onClick={handleRestored} color="secondary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertRestore;
