import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import TableStateComponent from './../TableStateComponent';

const columns = [
  { id: 'stateName', label: 'State Name' },
  { id: 'stateShortName', label: 'State Short Name' },
  { id: 'countryName', label: 'Country Name' },
  { id: 'stateAtive', label: 'State Active' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

const ListState = () => {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);


  const [submittedData, setSubmittedData] = useState({
    submit: false,
    active: '',
    country: '',
    search: '',
  });
  const [filterData, setFilterData] = useState({
    active: '',
    country: '',
    search: '',
    removed: '',
  });

  useEffect(() => {
    if (filterData.active === '' && filterData.country === '' && filterData.search === '') {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.active === ' ') filterData.active = '';
    if (filterData.country === ' ') filterData.country = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  useEffect(() => {
    let stateStorage = JSON.parse(localStorage.getItem('state_filter'));
    stateStorage !== null && setFilterData(stateStorage);

    stateStorage == null
      ? getStates()
      : stateStorage.active == '' &&
        stateStorage.country == '' &&
        stateStorage.search == '' &&
        stateStorage.removed == false
      ? getStates()
      : console.log('country...');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      active: filterData.active,
      country: filterData.country,
      search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    localStorage.setItem('state_filter', JSON.stringify(filterData));
    setLoading(true);
    if (filterData.active || filterData.country || filterData.search) {
      httpClient
        .get(`state/filter?q=${filterData.search}&stateActive=${filterData.active}&countryName=${filterData.country}`)
        .then(({ data }) => {
          if (data.success === true) {
            setState(data.data);
            setTotal(data.meta.total);
            // setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getStates();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const getStates = () => {
    setLoading(true);
    httpClient.get(`state?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setState(data.data);
        setTotal(data.meta.total);
        // setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    // console.log('direction', direction);
    setCurrentColumn(column);
    httpClient
      .get(`state?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setState(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `state/filter?q=${filterData.search}&stateActive=${filterData.active}&countryName=${filterData.country}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setState(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : 
      httpClient.get(`state?direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setState(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `state/filter?q=${filterData.search}&stateActive=${filterData.active}&countryName=${
              filterData.country
            }&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setState(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          })
      : 
      httpClient
          .get(`state?direction=desc&pagination=${+event.target.value}&page=${1}`)
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setState(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          });
  };

  const handleEdit = id => {
    history.push(`/country/addState/${id}`);
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getStates();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`state/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setState(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="State">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableStateComponent
              columns={columns}
              selectedProducts={state}
              title="List State"
              handleEdit={handleEdit}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleRemove={handleRemove}
              direction={direction}
              currentColumn={currentColumn}
            />
          </Box>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default ListState;
