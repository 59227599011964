import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddSyncFields from './AddSyncFields';
import AddSyncGroups from './AddSyncGroup';
import ListSyncFields from './ListSyncFields';
import ListSyncGroups from './ListSyncGroup';

const SyncSettings = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listSyncFields`} />
        <Route path={`${requestedUrl}/listSyncFields`} component={ListSyncFields} />
        <Route path={`${requestedUrl}/addSyncFields`} component={AddSyncFields} />
        <Route path={`${requestedUrl}/listSyncGroups`} component={ListSyncGroups} />
        <Route path={`${requestedUrl}/addSyncGroups`} component={AddSyncGroups} />
        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default SyncSettings;
