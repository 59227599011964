import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, IconButton, TextField, makeStyles, CircularProgress } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { httpClient } from 'appUtility/Api';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const qs = require('qs');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  text: {
    width: '100%',
  },
  buttonPadding: {
    padding: '10px 25px 20px 25px',
  },
}));

const AlertReset = props => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [loading, setLoading] = useState(false);

  const [alertData, setAlertData] = useState({
    open: true,
    success: '',
  });

  const [company, setCompany] = useState({
    companyName: '',
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    httpClient.get(`/company-contact/${props.activeID}`).then(({ data }) => {
      if (data.success) {
        setCompany({
          ...company,
          companyName: data.data.firstName + ' ' + data.data.lastName,
        });
      }
    });
  }, []);

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setCompany({
      ...company,
      [name]: value,
    });
  };

  const handleClose = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: false,
    });
  };

  const handleReset = () => {
    setLoading(true);
    httpClient
      .post(
        `company-contact/reset-password/${props.activeID}`,
        qs.stringify({
          password: company.password,
          confirmedPassword: company.confirmPassword,
        }),
      )
      .then(({ data }) => {
        if (data.success === true) {
          setOpen(true);
          setMessage("Password Reset Successful!");
          setMessageState('success');
          setLoading(false);
          setTimeout(() => {
            setAlertData({
              ...alertData,
              open: false,
              success: true,
            });
          }, 1000);
        } else {
          setOpen(true);
          setMessage('Failed to Reset Password');
          setMessageState('error');
          setLoading(false);
        }
      });
  };

  props.sendResetData(alertData);

  return (
    <Dialog open={alertData.open} onClose={handleClose}>
      <DialogTitle>
        <h2>Reset Password</h2>
        <IconButton onClick={handleClose} style={{ position: 'absolute', top: '10px', right: '20px' }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={4}>
            <Grid item sm={12}>
              <TextField
                label="Contact Name"
                value={company.companyName}
                variant="outlined"
                disabled
                className={classes.text}
              />
            </Grid>

            <Grid item sm={12}>
              <TextField
                label="Password"
                value={company.password}
                name="password"
                type="password"
                variant="outlined"
                onChange={handleChange}
                className={classes.text}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label="Confirm Password"
                value={company.confirmPassword}
                name="confirmPassword"
                variant="outlined"
                type="password"
                onChange={handleChange}
                className={classes.text}
                helperText={company.password !== company.confirmPassword && 'Password did not match.'}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.buttonPadding}>
        <Button onClick={handleClose} color="primary" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleReset}
          color="secondary"
          autoFocus
          variant="contained"
          disabled={(company.password !== company.confirmPassword || loading) ? true : false}>
          Reset
        </Button>
        {loading && (
          <CircularProgress size={25} style={{marginLeft:"10px"}} />
        )}
      </DialogActions>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default AlertReset;
