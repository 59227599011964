import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
import Dropzone from 'react-dropzone';
import { AddPhotoAlternateOutlined } from '@material-ui/icons';
import { userDetails } from '@fake-db/dashboards/widgets';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
  uploadImage: {
    cursor: 'pointer',
    textAlign: 'center',
    padding: '40px 0',
    border: `1px solid #999`,
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid #333`,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '45px',
    },
  },
  imgClass: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  snack:{
    "& .MuiAlert-action":{
      alignItems: "flex-start",
      paddingTop:"7px"
    }
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddUser = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [userID, setUserID] = useState(props && props.location.pathname.slice(16));
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    confirmPassword: '',
    adminPassword: '',
    active: false,
  });

  useEffect(() => {
    getSingleProfile();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    userID === ''
      ? httpClient
          .post(
            '/user',
            qs.stringify({
              username: userDetails.username,
              password: userDetails.password,
              confirmedPassword: userDetails.confirmPassword,
              firstName: userDetails.firstName,
              lastName: userDetails.lastName,
              userActive: userDetails.active === true ? 1 : 0,
              adminPassword: userDetails.adminPassword,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessageState('success');
              setMessage(data.message);
              setLoading(false);
              setUserDetails({
                firstName: '',
                lastName: '',
                username: '',
                password: '',
                confirmPassword: '',
                adminPassword: '',
                active: false,
              });
              setTimeout(() => {
                history.push('/system/listUsers');
              }, 1000);
            } else {
              setOpen(true);
              setMessageState('error');
              setMessage(data.message);
              setLoading(false);
            }
          })
      : httpClient
          .post(
            `/user/${userID}`,
            qs.stringify({
              username: userDetails.username,
              // password: userDetails.password,
              // confirmedPassword: userDetails.confirmPassword,
              firstName: userDetails.firstName,
              lastName: userDetails.lastName,
              userActive: userDetails.active == true ? 1 : 0,
              adminPassword: userDetails.adminPassword
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessageState('success');
              setMessage(data.message);
              setLoading(false);
              setTimeout(() => {
                history.push('/system/listUsers');
              }, 1000);
            } else {
              setOpen(true);
              setMessageState('error');
              setMessage(data.message);
              setLoading(false);
            }
          });
  };

  const getSingleProfile = () => {
    userID &&
      httpClient.get(`/user/${userID}`).then(({ data }) => {
        if (data.success === true) {
          setUserDetails({
            firstName: data.data.firstName,
            lastName: data.data.lastName,
            username: data.data.username,
            // password: '',
            // confirmPassword: '',
            // adminPassword: '',
            active: data.data.userActive == 1 ? true : false,
          });
        }
      });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: checked,
    });
  };

  const handleBack = () => {
    history.push('/system/listUsers');
  };

  return (
    <PageContainer heading={userID == '' ? 'Add User' : 'Edit User'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="First Name"
                variant="outlined"
                name="firstName"
                type="text"
                value={userDetails.firstName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Last Name"
                variant="outlined"
                name="lastName"
                type="text"
                value={userDetails.lastName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Username"
                variant="outlined"
                name="username"
                type="text"
                value={userDetails.username}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            {userID == '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  required
                  label="Password"
                  variant="outlined"
                  name="password"
                  type="password"
                  value={userDetails.password}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {userID == '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  required
                  label="Confirm Password"
                  variant="outlined"
                  name="confirmPassword"
                  helperText={userDetails.password !== userDetails.confirmPassword && 'Password does not match'}
                  type="password"
                  value={userDetails.confirmPassword}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {/* {userID == '' && ( */}
              <Grid item sm={12} md={12}>
                <TextField
                  required
                  label="Enter Your Admin Password"
                  variant="outlined"
                  name="adminPassword"
                  type="password"
                  value={userDetails.adminPassword}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            {/* )} */}

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="active"
                    checked={userDetails.active}
                    onChange={handleCheck}
                    value={userDetails.active}
                    color="primary"
                  />
                }
                label="User Active?"
              />
            </Grid>

            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={userDetails.password !== userDetails.confirmPassword ? true : loading ? true : false}>
                {userID == '' ? 'Add User' : 'Edit User'}
              </Button>
              {loading && <CircularProgress size={20} style={{ marginLeft: '10px' }} />}
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
        {messageState === "success" ?
            message
          : (message.length > 0 && message.map(msg => <p>{msg}</p>))
          }
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddUser;
