import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Button,
  CircularProgress,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useRef, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
// import Dropzone from 'react-dropzone';
// import { AddPhotoAlternateOutlined, Close, Cancel } from '@material-ui/icons';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
  // uploadImage: {
  //   cursor: 'pointer',
  //   textAlign: 'center',
  //   padding: '40px 0',
  //   border: `1px solid #999`,
  //   borderRadius: '5px',
  //   '&:hover': {
  //     border: `1px solid #333`,
  //   },
  //   '& .MuiSvgIcon-root': {
  //     fontSize: '45px',
  //   },
  // },
  // imgClass: {
  //   height: '100%',
  //   width: '100%',
  //   objectFit: 'cover',
  // },
  // addMoreUDF: {
  //   margin: '15px',
  //   width: '100%',
  // },
  // addMore: {
  //   margin: '15px',
  //   width: '100%',
  //   position: 'relative',
  // },
  // arrayField: {
  //   width: '100%',
  //   height: '56px',
  // },
  // btnAddMore: {
  //   textAlign: 'right',
  //   marginRight: '15px',
  // },
  // header: {
  //   marginTop: '20px',
  // },
  // remove: {
  //   position: 'absolute',
  //   right: '50px',
  //   top: '5px',
  // },
  // box: {
  //   marginBottom: '20px',
  // },
  // place:{
  //   padding:"10px",
  //   position:"absolute",
  //   top:"45px",
  //   right:"50px",
  //   display: "flex"
  // },
  snack: {
    '& .MuiAlert-action': {
      alignItems: 'flex-start',
      paddingTop: '7px',
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddSyncGroups = props => {
  const classes = useStyles();
  const ref = useRef();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState([]);
  const [messageState, setMessageState] = useState('');

  const [indexPlace, setIndexPlace] = useState('');

  const [groupID, setGroupID] = useState(props && props.location.pathname.slice(20));
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);
  const [groups, setSyncGroups] = useState({
    settingsGroupName: '',
    settingsGroupActive: false,
  });

  console.log('group id', groupID);

  useEffect(() => {
    getSingleSyncGroup();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    groupID === ''
      ? httpClient
          .post(
            '/field-setting-group',
            qs.stringify({
              settingsGroupName: groups.settingsGroupName,
              settingsGroupActive: groups.settingsGroupActive == true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessageState('success');
              setMessage(data.message);
              setLoading(false);
              setSyncGroups({
                settingsGroupName: '',
                settingsGroupActive: false,
              });
              setTimeout(() => {
                history.push('/sync/listSyncGroups');
              }, 1000);
            } else {
              setOpen(true);
              setMessageState('error');
              setMessage(data.message);
              setLoading(false);
            }
          })
      : httpClient
          .post(
            `/field-setting-group/${groupID}`,
            qs.stringify({
              settingsGroupName: groups.settingsGroupName,
              settingsGroupActive: groups.settingsGroupActive == true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessageState('success');
              setMessage(data.message);
              setLoading(false);
              setTimeout(() => {
                history.push('/sync/listSyncGroups');
              }, 1000);
            } else {
              setOpen(true);
              setMessageState('error');
              setMessage(data.message);
              setLoading(false);
            }
          });
  };

  const getSingleSyncGroup = () => {
    groupID &&
      httpClient.get(`/field-setting-group/${groupID}`).then(({ data }) => {
        if (data.success === true) {
          setSyncGroups({
            settingsGroupName: data.data.settingsGroupName,
            settingsGroupActive: data.data.settingsGroupActive == 1 ? true : false,
          });
        }
      });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setSyncGroups({
      ...groups,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setSyncGroups({
      ...groups,
      [name]: checked,
    });
  };

  const handleBack = () => {
    history.push('/sync/listSyncGroups');
  };

  return (
    <PageContainer heading={groupID === '' ? 'Add Sync Group' : 'Edit Sync Group'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                type="text"
                label="Sync Group Name"
                variant="outlined"
                name="settingsGroupName"
                value={groups.settingsGroupName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="settingsGroupActive"
                    checked={groups.settingsGroupActive}
                    onChange={handleCheck}
                    value={groups.settingsGroupActive}
                    color="primary"
                  />
                }
                label="Sync Group Active?"
              />
            </Grid>

            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={loading ? true : false}>
                {groupID === '' ? 'Add Sync Group' : 'Edit Sync Group'}
              </Button>
              {loading && <CircularProgress size={25} style={{ marginLeft: '10px' }} />}
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        className={classes.snack}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {messageState === 'success' ? message : message.length > 0 && message.map(msg => <p>{msg}</p>)}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddSyncGroups;
