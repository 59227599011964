import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Dashboards from './Dashboards';
// import Components from './Components';
// import Apps from './Apps';
// import Extensions from './Extensions';
// import Charts from './Charts';
// import Maps from './Maps';
// import Widgets from './Widgets';
// import Metrics from './Metrics';
import Login from './Auth/Login';
// import Signup from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import { useSelector } from 'react-redux';
import { useLocation, BrowserRouter as Router } from 'react-router-dom';
import NotFound from './ExtraPages/404';
import TourGuide from './TourGuide';
import Settings from './Settings';
import Company from './Company';
import DataSource from './DataSources';
import IntegrationOutput from './IntegrationOutput';
import System from './System';
import Logout from './Logout';
import Profile from './Profile';
import Country from './Country';
import ResetPasswordPage from './Auth/ResetPassword';
import SyncSettings from './SyncSettings';

const loginToken = JSON.parse(sessionStorage.getItem('token'));
// const companyToken = JSON.parse(sessionStorage.getItem('companyToken'));

const RestrictedRoute = ({ component: Component, ...rest }) => {
  // const { authUser } = useSelector(({ auth }) => auth);

  return (
    <Route
      {...rest}
      render={props =>
        loginToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/admin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  // const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  if ((location.pathname === '' || location.pathname === '/') && loginToken) {
    return <Redirect to={'/dashboard'} />;
  } else if ((location.pathname === '' || location.pathname === '/') && !loginToken) {
    return <Redirect to={'/admin'} />;
  } else if (location.pathname === '/admin/' && loginToken) {
    return <Redirect to={'/dashboard'} />;
  } else if (location.pathname === '/admin/' && !loginToken) {
    return <Redirect to={'/admin'} />;
  } else if (location.pathname === '/admin/' && loginToken) {
    return <Redirect to={'/admin'} />;
  }
  // if ((location.pathname === '/forgotPassword') && (loginToken == null)) {
  //   return <Redirect to={'/forgotPassword'} />;
  // }
  // else if (location.pathname === '/admin' && loginToken) {
  //   return <Redirect to={'/dashboard'} />;
  // // } else if (location.pathname === '/dashboard' && !loginToken) {
  // //   return <Redirect to={'/admin'} />;

  // else if (location.pathname !== '/admin' && !loginToken) {
  //   return <Redirect to={'/admin'} />;
  // }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/dashboard" component={Dashboards} />
        {/* <Route path="/widgets" component={Widgets} />
        <Route path="/metrics" component={Metrics} />
        <Route path="/components" component={Components} />
        <Route path="/extensions" component={Extensions} />
        <Route path="/visualization/chart" component={Charts} />
        <Route path="/visualization/map" component={Maps} />
        <Route path="/extra-pages" component={ExtraPages} />
        <Route path="/apps" component={Apps} />
        <Route path="/admin" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPassword} /> */}
        <RestrictedRoute path="/company" component={Company} />
        <RestrictedRoute path="/integration" component={IntegrationOutput} />
        <RestrictedRoute path="/system" component={System} />
        <RestrictedRoute path="/settings" component={Settings} />
        <RestrictedRoute path="/dataSource" component={DataSource} />
        <RestrictedRoute path="/profile" component={Profile} />
        <RestrictedRoute path="/country" component={Country} />
        <RestrictedRoute path="/sync" component={SyncSettings} />

        {/* <PublicRoute path="/404" component={NotFound} /> */}
        <Route path="/admin" component={Login} />
        <Route path="/forgotPassword" component={ForgotPasswordPage} />
        <Route path="/ResetPassword" component={ResetPasswordPage} />
        <Route path="/logout" component={Logout} />

        <Route path="*" component={NotFound} />
      </Switch>

      {/* 
      {location.pathname !== '/admin' && location.pathname !== '/signup' && location.pathname !== '/forgot-password' && (
        <TourGuide />
      )} */}
    </React.Fragment>
  );
};

export default Routes;
