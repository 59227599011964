import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddUser from './AddUser';
import ListUsers from './ListUser';

const System = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listUsers`} />
        <Route path={`${requestedUrl}/listUsers`} component={ListUsers} />
        <Route path={`${requestedUrl}/addUser`} component={AddUser} />

        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default System;
