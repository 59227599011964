import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer'
import React from 'react'

const Settings = () => {
    return (
        <PageContainer heading="Settings">
            
        </PageContainer>
    )
}

export default Settings
