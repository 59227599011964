import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { httpClient } from 'appUtility/Api';
import {
  Checkbox,
  Grid,
  FormControlLabel,
  makeStyles,
  IconButton,
  CircularProgress,
  Switch,
  Tooltip,
} from '@material-ui/core';
import { Close, Info } from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { set } from 'lodash';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  iconClose: {
    color: '#fff',
  },
  header: {
    margin: '10px 0',
    '& h3': {
      fontSize: '20px',
      fontWeight: 'bold',
    },
  },
  content: {
    paddingTop: '20px',
    paddingBottom: '40px',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ManageDialog = props => {
  const classes = useStyles();
  const [alertData, setAlertData] = useState({
    open: true,
    success: '',
  });
  const [manageFields, setManageFields] = useState({
    field: [],
    status: [],
    fieldIDs: [],
  });
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState([]);
  const [messageState, setMessageState] = useState('');

  useEffect(() => {
    getSyncFields();
    getSyncDataSource();
  }, []);

  const getSyncFields = () => {
    setLoading(true);
    httpClient.get('field-setting').then(({ data }) => {
      if (data.success == true) {
        setFields(data.data);
        setLoading(false);
      }
    });
  };

  var allGroups =
    fields &&
    fields.map(item => {
      // return item.syncFieldSettingGroup && item.syncFieldSettingGroup.syncFieldSettingGroupID;
      return item.syncFieldSettingGroup;
    });

  var uniqueGroups = [];
  allGroups.filter(item => {
    var i = uniqueGroups.findIndex(x => x.syncFieldSettingGroupID === item.syncFieldSettingGroupID);
    if (i <= -1) {
      uniqueGroups.push(item);
    }
    return null;
  });

  // var uniqueGroups = allGroups.filter(
  //   (item, i , arr) =>{
  //     return arr.indexOf(item.syncFieldSettingGroupID) == i;
  //   }
  // );

  const getSyncDataSource = () => {
    setLoading(true);
    httpClient.get(`sync-setting/ByDataSourceTypeID/${props.selectID}`).then(({ data }) => {
      if (data.success == true) {
        var newSync = [];
        var newSyncID = [];
        data.data.map(item => {
          newSync[item.syncFieldSettingID] = true;
          newSyncID.push(item.syncFieldSettingID);
          // item.syncFieldSettingID ? newSync[item.syncFieldSettingID] = "true" : "";
        });
        // manageFields.field = newSync;
        setManageFields({
          ...manageFields,
          field: newSync,
          fieldIDs: newSyncID,
        });
        setLoading(false);
      }
    });
  };

  const handleClose = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: false,
    });
  };

  const handleSave = () => {
    // setAlertData({
    //   ...alertData,
    //   open: false,
    //   success: true,
    // });
    setBtnLoad(true);
    var newIDS = manageFields.fieldIDs.map(item => {
      return item;
    });
    httpClient
      .post(
        `sync-setting/${props.selectID}`,
        qs.stringify({
          // fieldSettingID: manageFields.field,
          syncFieldSettingID: newIDS,
          // datasourcetypeID: props.selectID
        }),
      )
      .then(({ data }) => {
        if (data.success == true) {
          setOpen(true);
          setMessageState('success');
          setMessage('Sync settings has been updated successfully');
          setBtnLoad(false);
          setTimeout(() => {
            setAlertData({
              ...alertData,
              open: false,
              success: true,
            });
          }, 2000);
        } else {
          setOpen(true);
          setMessageState('error');
          setMessage(data.message);
          setBtnLoad(false);
        }
      });
  };

  const handleCheck = (e, id, index) => {
    const { checked } = e.target;
    manageFields.field[id] = checked;
    // manageFields.status = checked;
    if (manageFields.field[id] == true) {
      manageFields.fieldIDs.push(id);
    } else if (manageFields.field[id] == false) {
      manageFields.fieldIDs.splice(index, 1);
    }
    setManageFields({
      ...manageFields,
      field: manageFields.field,
      // status: manageFields.status,
    });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  props.sendManage(alertData);

  return (
    <Dialog open={alertData.open} maxWidth={'md'} fullWidth={true} onClose={handleClose}>
      <DialogTitle className={classes.root}>
        <h2>Manage Sync Setting</h2>
        <IconButton onClick={handleClose} style={{ position: 'absolute', right: '10px', top: '10px' }}>
          <Close className={classes.iconClose} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {loading ? (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={3}>
            {uniqueGroups.length > 0 &&
              uniqueGroups &&
              uniqueGroups.map((item, index) => {
                return (
                  <Grid item sm={12} md={6}>
                    <Grid item sm={12} md={12}>
                      <div className={classes.header}>
                        <h3>{item.syncFieldSettingGroupName}</h3>
                      </div>
                    </Grid>
                    {fields.length > 0 &&
                      fields.map((field, index) => {
                        if (
                          item.syncFieldSettingGroupID ===
                            (field.syncFieldSettingGroup && field.syncFieldSettingGroup.syncFieldSettingGroupID) &&
                          field.syncFieldSettingActive != 0
                        ) {
                          return (
                            <Grid item sm={12} md={12}>
                              <FormControlLabel
                                control={
                                  // <Checkbox
                                  //   checked={manageFields.field[field.syncFieldSettingID] == true ? true : false}
                                  //   onChange={e => handleCheck(e, field.syncFieldSettingID, index)}
                                  //   value={manageFields.field[field.syncFieldSettingID]}
                                  //   color="primary"
                                  // />
                                  <Switch
                                    checked={manageFields.field[field.syncFieldSettingID] == true ? true : false}
                                    onChange={e => handleCheck(e, field.syncFieldSettingID, index)}
                                    value={manageFields.field[field.syncFieldSettingID]}
                                    color="primary"
                                  />
                                }
                                label={field.syncFieldSettingName}
                              />
                              <Tooltip title={field.syncFieldSettingDescription}>
                                <Info
                                  style={{
                                    color: '#999',
                                    position: 'relative',
                                    top: '8px',
                                  }}
                                />
                              </Tooltip>
                            </Grid>
                          );
                        }
                      })}
                  </Grid>
                );
              })}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {btnLoad && <CircularProgress size={20} />}
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          autoFocus
          disabled={manageFields.fieldIDs.length > 0 ? false : true}>
          Save
        </Button>
      </DialogActions>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={messageState}>
          {messageState === 'success' ? message : message.length > 0 && message.map(msg => <p>{msg}</p>)}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default ManageDialog;
