import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { httpClient } from 'appUtility/Api';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
  close: {
    position: 'absolute',
    right: '20px',
    top: '10px',
  },
}));

const AlertForm = props => {
  const classes = useStyles();
  const [alertData, setAlertData] = useState({
    open: true,
    success: '',
  });

  const [integration, setIntegration] = useState({
    integrationType: props.edit ? '' : props.integrationID,
    // apiURL: '',
    // username: '',
    // password: '',
    // udf1: '',
    // udf2: '',
    // udf3: '',
    // udf4: '',
    // udf5: '',
    notes: '',
    helpURL: '',
    active: false,

    udf1Label: '',
    udf2Label: '',
    udf3Label: '',
    udf4Label: '',
    udf5Label: '',
    udf6Label: '',
    udf7Label: '',
    udf8Label: '',
    udf9Label: '',
    udf10Label: '',
    udf1Enc: '',
    udf2Enc: '',
    udf3Enc: '',
    udf4Enc: '',
    udf5Enc: '',
    udf6Enc: '',
    udf7Enc: '',
    udf8Enc: '',
    udf9Enc: '',
    udf10Enc: '',
    udf1Value: '',
    udf2Value: '',
    udf3Value: '',
    udf4Value: '',
    udf5Value: '',
    udf6Value: '',
    udf7Value: '',
    udf8Value: '',
    udf9Value: '',
    udf10Value: '',
  });
  const [listIntegrations, setListIntegrations] = useState([]);

  useEffect(() => {
    getIntegrations();
    props.edit === true && getSingleIntegration();
  }, []);

  listIntegrations &&
    listIntegrations.map((integ, index) => {
      if (integ.integrationID == integration.integrationType) {
        integ.integrationUDF1Label != '' && (integration.udf1Label = integ.integrationUDF1Label);
        integ.integrationUDF2Label != '' && (integration.udf2Label = integ.integrationUDF2Label);
        integ.integrationUDF3Label != '' && (integration.udf3Label = integ.integrationUDF3Label);
        integ.integrationUDF4Label != '' && (integration.udf4Label = integ.integrationUDF4Label);
        integ.integrationUDF5Label != '' && (integration.udf5Label = integ.integrationUDF5Label);
        integ.integrationUDF6Label != '' && (integration.udf6Label = integ.integrationUDF6Label);
        integ.integrationUDF7Label != '' && (integration.udf7Label = integ.integrationUDF7Label);
        integ.integrationUDF8Label != '' && (integration.udf8Label = integ.integrationUDF8Label);
        integ.integrationUDF9Label != '' && (integration.udf9Label = integ.integrationUDF9Label);
        integ.integrationUDF10Label != '' && (integration.udf10Label = integ.integrationUDF10Label);

        integ.uDF1Encryption != '' && (integration.udf1Enc = integ.uDF1Encryption);
        integ.uDF2Encryption != '' && (integration.udf2Enc = integ.uDF2Encryption);
        integ.uDF3Encryption != '' && (integration.udf3Enc = integ.uDF3Encryption);
        integ.uDF4Encryption != '' && (integration.udf4Enc = integ.uDF4Encryption);
        integ.uDF5Encryption != '' && (integration.udf5Enc = integ.uDF5Encryption);
        integ.uDF6Encryption != '' && (integration.udf6Enc = integ.uDF6Encryption);
        integ.uDF7Encryption != '' && (integration.udf7Enc = integ.uDF7Encryption);
        integ.uDF8Encryption != '' && (integration.udf8Enc = integ.uDF8Encryption);
        integ.uDF9Encryption != '' && (integration.udf9Enc = integ.uDF9Encryption);
        integ.uDF10Encryption != '' && (integration.udf10Enc = integ.uDF10Encryption);
      }
    });

  const getIntegrations = () => {
    httpClient.get(`integration-type`).then(({ data }) => {
      if (data.success) {
        setListIntegrations(data.data);
      }
    });
  };

  const getSingleIntegration = () => {
    httpClient.get(`/integration/${props.integrationID}`).then(({ data }) => {
      if (data.success === true) {
        setIntegration({
          ...integration,
          integrationType: data.data.integration.integrationID,
          // apiURL: data.data.compIntegrationAPIUrl,
          // username: data.data.compIntegrationUserName,
          // password: data.data.Password,
          udf1Value: data.data.compIntegrationUDF1Value,
          udf2Value: data.data.compIntegrationUDF2Value,
          udf3Value: data.data.compIntegrationUDF3Value,
          udf4Value: data.data.compIntegrationUDF4Value,
          udf5Value: data.data.compIntegrationUDF5Value,
          udf6Value: data.data.compIntegrationUDF6Value,
          udf7Value: data.data.compIntegrationUDF7Value,
          udf8Value: data.data.compIntegrationUDF8Value,
          udf9Value: data.data.compIntegrationUDF9Value,
          udf10Value: data.data.compIntegrationUDF10Value,
          notes: data.data.compIntegrationNotes,
          helpURL: data.data.compIntegrationHelpURL,
          active: data.data.compIntegrationActive == 'Active' ? true : false,
        });
      }
    });
  };

  const handleClose = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: false,
    });
  };

  const handleSubmit = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: true,
    });
  };

  const handleChange = e => {
    const { value, name } = e.target;
    setIntegration({
      ...integration,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { checked, name } = e.target;
    setIntegration({
      ...integration,
      [name]: checked,
    });
  };

  // console.log('props alert', props);

  //   props.sendAlert(alertData);
  props.sendVerifyData({ integration: integration, alert: alertData });

  // console.log('integration ??', integration);

  return (
    <Dialog open={alertData.open} onClose={handleClose} maxWidth="md">
      <DialogTitle>
        <h2>Company Integration Association</h2>
        <IconButton onClick={handleClose} className={classes.close}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Integration Type</InputLabel>
                <Select
                  disabled={props.edit === true ? true : false}
                  value={integration.integrationType}
                  label="Integration Type"
                  onChange={handleChange}
                  name="integrationType">
                  <MenuItem value="">
                    <em>Select Integration Type</em>
                  </MenuItem>
                  {listIntegrations.map(integ => {
                    return (
                      <MenuItem key={integ.integrationID} value={integ.integrationID}>
                        {integ.integrationName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item sm={12} md={12}>
              <TextField
                label="Api URL"
                variant="outlined"
                name="apiURL"
                type="text"
                value={integration.apiURL}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                required
                label="Username"
                variant="outlined"
                name="username"
                type="text"
                value={integration.username}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                required
                label="Password"
                variant="outlined"
                name="password"
                type="password"
                value={integration.password}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid> */}

            {integration.udf1Label !== '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  label={integration.udf1Label}
                  variant="outlined"
                  name="udf1Value"
                  type={integration.udf1Enc != true ? 'text' : 'password'}
                  value={integration.udf1Value}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {integration.udf2Label !== '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  label={integration.udf2Label}
                  variant="outlined"
                  name="udf2Value"
                  type={integration.udf2Enc != true ? 'text' : 'password'}
                  value={integration.udf2Value}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {integration.udf3Label !== '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  label={integration.udf3Label}
                  variant="outlined"
                  name="udf3Value"
                  type={integration.udf3Enc != true ? 'text' : 'password'}
                  value={integration.udf3Value}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {integration.udf4Label !== '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  label={integration.udf4Label}
                  variant="outlined"
                  name="udf4Value"
                  type={integration.udf4Enc != true ? 'text' : 'password'}
                  value={integration.udf4Value}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {integration.udf5Label !== '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  label={integration.udf5Label}
                  variant="outlined"
                  name="udf5Value"
                  type={integration.udf1Enc != true ? 'text' : 'password'}
                  value={integration.udf5Value}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {integration.udf6Label !== '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  label={integration.udf6Label}
                  variant="outlined"
                  name="udf6Value"
                  type={integration.udf6Enc != true ? 'text' : 'password'}
                  value={integration.udf6Value}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {integration.udf7Label !== '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  label={integration.udf7Label}
                  variant="outlined"
                  name="udf7Value"
                  type={integration.udf7Enc != true ? 'text' : 'password'}
                  value={integration.udf7Value}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {integration.udf8Label !== '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  label={integration.udf8Label}
                  variant="outlined"
                  name="udf8Value"
                  type={integration.udf1Enc != true ? 'text' : 'password'}
                  value={integration.udf8Value}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {integration.udf9Label !== '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  label={integration.udf9Label}
                  variant="outlined"
                  name="udf9Value"
                  type={integration.udf9Enc != true ? 'text' : 'password'}
                  value={integration.udf9Value}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {integration.udf10Label !== '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  label={integration.udf10Label}
                  variant="outlined"
                  name="udf10Value"
                  type={integration.udf01Enc != true ? 'text' : 'password'}
                  value={integration.udf10Value}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            <Grid item sm={12} md={12}>
              <TextField
                multiline
                rows={4}
                label="Notes"
                variant="outlined"
                name="notes"
                type="text"
                value={integration.notes}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                label="Help URL"
                variant="outlined"
                name="helpURL"
                type="text"
                value={integration.helpURL}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="active"
                    checked={integration.active}
                    onChange={handleCheck}
                    value={integration.active}
                    color="primary"
                  />
                }
                label="Integration Active?"
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions style={{ paddingBottom: '15px' }}>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="secondary" variant="outlined" autoFocus>
          Verify
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertForm;
