import React, { lazy, Suspense, useEffect } from 'react';
import { Card, makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width:"100%",
    justifyContent:"center"
  },
  logout: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: '40px',
    width:"300px",
    height:"200px"
  },
}));

const Logout = ({ match }) => {

  useEffect(() => {
    sessionStorage.clear();
    window.location.pathname = "/admin";
  }, [])
  const classes = styles();
  return (
    <div className={classes.root}>
      <Card className={classes.logout}>Logging out...</Card>
    </div>
  );
};

export default Logout;
