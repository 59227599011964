import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddDataSource from './AddDataSource';
import ListDataSource from './ListDataSource';
import ListDeletedDataSource from './ListDeletedDataSource';

const DataSource = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/listDataSource`} />
        <Route path={`${requestedUrl}/listDataSource`} component={ListDataSource} />
        <Route path={`${requestedUrl}/addDataSource`} component={AddDataSource} />
        <Route path={`${requestedUrl}/listDeletedSource`} component={ListDeletedDataSource} />


        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default DataSource;
