import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControlLabel,
  IconButton,
  Checkbox,
  Button,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useRef, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
import Dropzone from 'react-dropzone';
import { AddPhotoAlternateOutlined, Cancel, Close } from '@material-ui/icons';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
  uploadImage: {
    cursor: 'pointer',
    textAlign: 'center',
    padding: '40px 0',
    border: `1px solid #999`,
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid #333`,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '45px',
    },
  },
  imgClass: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  addMoreUDF: {
    margin: '15px',
    width: '100%',
  },
  addMore: {
    margin: '15px',
    width: '100%',
    position:"relative"
  },
  arrayField: {
    width: '100%',
    height: '56px',
  },
  btnAddMore: {
    textAlign: 'right',
    marginRight: '15px',
  },
  header: {
    marginTop: '20px',
  },
  remove:{
    position:"absolute",
    right:"50px",
    top:"5px",
  },
  box:{
  },
  place:{
    padding:"10px",
    position:"absolute",
    top:"45px",
    right:"50px",
    display: "flex"
  },
  snack:{
    "& .MuiAlert-action":{
      alignItems: "flex-start",
      paddingTop:"7px"
    }
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddIntegration = props => {
  const classes = useStyles();
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState([]);
  const [messageState, setMessageState] = useState('');

  const [integrationID, setIntegrationID] = useState(props && props.location.pathname.slice(28));

  const [loading, setLoading] = useState(false);

  const [indexPlace, setIndexPlace] = useState("");

  const [integration, setIntegration] = useState({
    integrationName: '',
    integrationImage: '',
    integrationHelpURL: '',
    integrationNotes: '',
    integrationActive: false,
  });

  const [fieldDetails, setFieldDetails] = useState({
    udf: [''],
    encription: [''],
  });

  useEffect(() => {
    getSingleIntegration();
  }, []);

  useEffect(() => {
    document.addEventListener('click', closeIndex, true);
    return () => {
        document.removeEventListener('click', closeIndex, true);
    };
  },[indexPlace])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!integration.integrationImage) {
      setOpen(true);
      setMessageState('error');
      setMessage(['Please Add Image']);
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append('integrationName', integration.integrationName);
      formData.append('integrationHelpURL', integration.integrationHelpURL);
      formData.append('integrationNotes', integration.integrationNotes);
      formData.append('integrationActive', integration.integrationActive === true ? 1 : 0);
      formData.append('integrationImage', integration.integrationImage.path ? integration.integrationImage : ' ');
      fieldDetails.udf.map((field, index) => {
        formData.append(`integrationUDF` + Math.ceil(index + 1) + `Label`, field);
      });

      fieldDetails.encription.map((field, index) => {
        formData.append(`uDF` + Math.ceil(index + 1) + `Encryption`, field == true ? 1 : 0);
      });

      integrationID === ''
        ? httpClient.post('/integration-type', formData).then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessageState('success');
              setMessage(data.message);
              setLoading(false);
              setIntegration({
                integrationName: '',
                integrationImage: '',
                integrationHelpURL: '',
                integrationNotes: '',
                integrationActive: false,
              });
              setTimeout(() => {
                history.push('/integration/listIntegrations');
              }, 1000);
            } else {
              setOpen(true);
              setMessageState('error');
              setMessage(data.message);
              setLoading(false);
            }
          })
        : httpClient.post(`/integration-type/${integrationID}`, formData).then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessageState('success');
              setMessage(data.message);
              setLoading(false);
              setTimeout(() => {
                history.push('/integration/listIntegrations');
              }, 1000);
            } else {
              setOpen(true);
              setMessageState('error');
              setMessage(data.message);
              setLoading(false);
            }
          });
    }
  };

  const getSingleIntegration = () => {
    integrationID &&
      httpClient.get(`/integration-type/${integrationID}`).then(({ data }) => {
        if (data.success === true) {
          setIntegration({
            integrationName: data.data.integrationName,
            integrationImage: data.data.integrationImage,
            integrationHelpURL: data.data.integrationHelpURL,
            integrationNotes: data.data.integrationNotes,
            integrationActive: data.data.integrationActive === 'Active' ? true : false,
          });
          var udfData = [];
          var encData= [];
          fieldDetails.udf.map((u,index) => {
            data.data.integrationUDF1Label != "" && (udfData.push(data.data.integrationUDF1Label));
            data.data.integrationUDF2Label != "" && (udfData.push(data.data.integrationUDF2Label));
            data.data.integrationUDF3Label != "" && (udfData.push(data.data.integrationUDF3Label));
            data.data.integrationUDF4Label != "" && (udfData.push(data.data.integrationUDF4Label));
            data.data.integrationUDF5Label != "" && (udfData.push(data.data.integrationUDF5Label));
            data.data.integrationUDF6Label != "" && (udfData.push(data.data.integrationUDF6Label));
            data.data.integrationUDF7Label != "" && (udfData.push(data.data.integrationUDF7Label));
            data.data.integrationUDF8Label != "" && (udfData.push(data.data.integrationUDF8Label));
            data.data.integrationUDF9Label != "" && (udfData.push(data.data.integrationUDF9Label));
            data.data.integrationUDF10Label != "" && (udfData.push(data.data.integrationUDF10Label));
          })

          fieldDetails.encription.map((u,index) => {
             encData.push(data.data.uDF1Encryption);
            encData.push(data.data.uDF2Encryption);
            encData.push(data.data.uDF3Encryption);
            encData.push(data.data.uDF4Encryption);
            encData.push(data.data.uDF5Encryption);
            encData.push(data.data.uDF6Encryption);
            encData.push(data.data.uDF7Encryption);
             encData.push(data.data.uD81Encryption);
             encData.push(data.data.uDF9Encryption);
             encData.push(data.data.uDF10Encryption);
          })


          setFieldDetails({
            udf: udfData,
            encription: encData
          })
        }
      });
  };

  // console.log('field details', fieldDetails);

  const handleChange = e => {
    const { name, value } = e.target;
    setIntegration({
      ...integration,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setIntegration({
      ...integration,
      [name]: checked,
    });
  };

  const onDrop = file => {
    // return file[0];
    setIntegration({
      ...integration,
      // Image: (URL.createObjectURL(file[0]))
      integrationImage: file[0],
    });
  };

  const handleBack = () => {
    history.push('/integration/listintegrations');
  };

  const handleChangeUDF = (e, index) => {
    const { name, value } = e.target;
    fieldDetails.udf[index] = value;
    setFieldDetails({
      ...fieldDetails,
      udf: fieldDetails.udf,
    });
  };

  const handleEncription = (e, index) => {
    const { name, checked } = e.target;
    fieldDetails.encription[index] = checked;
    setFieldDetails({
      ...fieldDetails,
      encription: fieldDetails.encription,
    });
  };

  const handleAdd = () => {
    setFieldDetails({
      ...fieldDetails,
      udf: [...fieldDetails.udf, ''],
      encription: [...fieldDetails.encription, ''],
    });
  };

  const handleRemove = (e, index) => {
    fieldDetails.udf.splice(index,1);
    fieldDetails.encription.splice(index,1);
    setFieldDetails({
      ...fieldDetails,
      udf: fieldDetails.udf,
      encription: fieldDetails.encription
    })
  }


  const handleRemoveNot = (e,index) => {
    
    setIndexPlace(index);
  }

  const closeIndex = () => {
    setIndexPlace("");
  }

  // console.log('integration >>>>', integration);


  return (
    <PageContainer heading={integrationID === '' ? 'Add Integration Type' : 'Edit Integration Type'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Integration Name"
                variant="outlined"
                name="integrationName"
                type="text"
                value={integration.integrationName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={3}>
              <div>
                <Dropzone onDrop={onDrop}>
                  {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
                    return (
                      <div>
                        {integration.integrationImage ? (
                          integration.integrationImage.path ? (
                            <img
                              src={URL.createObjectURL(integration.integrationImage)}
                              className={classes.imgClass}
                              alt={integration.integrationImage.name}
                            />
                          ) : (
                            <img
                              src={integration.integrationImage}
                              className={classes.imgClass}
                              alt={integration.integrationName}
                            />
                          )
                        ) : (
                          <div {...getRootProps()} className={classes.uploadImage}>
                            <input {...getInputProps()} />
                            <AddPhotoAlternateOutlined color="primary" />
                            <p>Upload Image here</p>
                          </div>
                        )}
                        <div style={{ textAlign: 'center', marginTop: '5px' }}>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              setIntegration({
                                ...integration,
                                integrationImage: '',
                              })
                            }>
                            {' '}
                            Reset Image
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                </Dropzone>
              </div>
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                type="text"
                label="Help Link (URL)"
                variant="outlined"
                name="integrationHelpURL"
                value={integration.integrationHelpURL}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                multiline
                rows={4}
                label="Notes"
                variant="outlined"
                name="integrationNotes"
                type="text"
                value={integration.integrationNotes}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} className={classes.header}>
              <h3>User Defined Fields</h3>
            </Grid>

            <Grid container spacing={4} className={classes.box}>
              <Grid item sm={6} md={6}>
                {fieldDetails.udf.map((field, index) => {
                  return (
                    <div className={classes.addMoreUDF}>
                      <TextField
                        type="text"
                        label={`User Defined Field ${Math.ceil(index+1)}`}
                        value={field}
                        onChange={e => handleChangeUDF(e, index)}
                        className={classes.text}
                        variant="outlined"
                      />
                    </div>
                  );
                })}
              </Grid>

              <Grid item sm={6} md={6}>
                {fieldDetails.encription.map((enc, index) => {
                  if(index < fieldDetails.udf.length){
                  return (
                    <div className={classes.addMore} ref={ref}>
                      <FormControlLabel
                        className={classes.arrayField}
                        control={
                          <Checkbox checked={enc} onChange={e => handleEncription(e, index)} value={enc} color="primary" />
                        }
                        label="Encription Required?"
                      />
                      {fieldDetails.udf.length > 1 &&
                      <>
                      <Tooltip title="Remove" className={classes.remove}>
                      <IconButton onClick={e => integrationID == "" ? handleRemove(e, index) : handleRemoveNot(e,index)}>
                        <Close color="primary" />
                      </IconButton>
                    </Tooltip>
                    {indexPlace === index &&
                    <Card className={classes.place} >
                      <div>
                        Please contact admin to delete this UDF field
                      </div>
                      <Cancel onClick={closeIndex} fontSize='small' style={{ cursor:"pointer" }} />
                    </Card>}
                    </>
                    }
                    </div>
                  )
                  }
                })}
                
              </Grid>
              <Grid item sm={12} className={classes.btnAddMore}>
                <Button variant="outlined" color="secondary" onClick={e => handleAdd(e)} style={{ marginLeft: '10px' }} disabled={fieldDetails.udf.length > 9 ? true : false}>
                  Add More +
                </Button>
              </Grid>
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="integrationActive"
                    checked={integration.integrationActive}
                    onChange={handleCheck}
                    value={integration.integrationActive}
                    color="primary"
                  />
                }
                label="Integration Active?"
              />
            </Grid>

            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={loading ? true : false}>
                {integrationID === '' ? 'Add Integration' : 'Edit Integration'}
              </Button>
              {loading && <CircularProgress size={25} style={{ marginLeft: '10px' }} />}
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
        {messageState === "success" ?
            message
          : (message.length > 0 && message.map(msg => <p>{msg}</p>))
          }
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddIntegration;
