import React, { useState, useEffect } from 'react';
// import CmtCard from '../../../../@coremat/CmtCard';
// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
// import { intranet } from '../../../../@fake-db';
// import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
// import ProductsList from './ProductsList';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button, Collapse, Grid, MenuItem, FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import { history } from 'redux/store';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';
import { httpClient } from 'appUtility/Api';

const WeeklySales = props => {
  // const { productsList } = intranet;
  const classes = useStyles();
  // const [selectedProducts, setSelectedProducts] = useState(productsList);
  // const [showChart, setShowChart] = useState(false);
  const [filter, setFilter] = useState(false);
  const [country, setCountry]= useState(false);
  // const [state, setState] = useState([]);
  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
  }, [props.submittedData]);

  const sendTo = () => {
    history.push('/country/addState');
  };

  const getCountry = () => {
    httpClient.get('/country/all').then(({ data }) => {
      if (data) {
        setCountry(data.data);
      } else {
      }
    });
  };

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  // var myCountry = "";
  // country && country.map((con) => {
  //   if(con.countryID === props.filterData.country){
  //     return myCountry = con.countryName;
  //   }
  // });


  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          {/* <div className={classes.formGroup}>
            <input
              placeholder="Search..."
              type="search"
              onChange={props.onChangeSearch}
              value={props.searchValue}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              className={classes.searchForm}
            />
            {!props.searchValue && <SearchIcon fontSize="small" className={classes.searchIcon} />}
          </div> */}
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          <Button color="primary" variant="contained" onClick={sendTo} style={{ marginRight: '10px' }}>
            +Add
          </Button>
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={12}>
                  <InputLabel className={classes.inputLabel}>Search By Name/Short Name</InputLabel>
                  <TextField
                    variant="outlined"
                    name="search"
                    value={props.filterData.search}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Active</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="active"
                      value={props.filterData.active}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;Select</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Country</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="country"
                      // label="State"
                      value={props.filterData.country}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">
                        <em>Select</em>
                      </MenuItem>
                      {country &&
                        country.map(count => (
                          <MenuItem key={count.countryName} value={count.countryName}>
                            {count.countryName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}
        {props.submittedData.active || props.submittedData.country || props.submittedData.search ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>
            {props.submittedData.active && (
              <p>
                Active: <span>{props.submittedData.active == 1 ? 'Yes' : 'No'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('active')} />
              </p>
            )}
            {props.submittedData.country && (
              <p>
                Country:<span> {props.submittedData.country} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('country')} />
              </p>
            )}
            {props.submittedData.search && (
              <p>
                Search:<span> {props.submittedData.search} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('search')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          handleEdit={props.handleEdit}
          // handleDelete={props.handleDelete}
          // handleInventory={props.handleInventory}
          loading={props.loading}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          currentColumn={props.currentColumn}
          direction={props.direction}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
