import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
import Dropzone from 'react-dropzone';
import { AddPhotoAlternateOutlined } from '@material-ui/icons';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
  uploadImage: {
    cursor: 'pointer',
    textAlign: 'center',
    padding: '40px 0',
    border: `1px solid #999`,
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid #333`,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '45px',
    },
  },
  imgClass: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  snack:{
    "& .MuiAlert-action":{
      alignItems: "flex-start",
      paddingTop:"7px"
    }
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddCompany = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState([]);
  const [messageState, setMessageState] = useState('');

  const [companyID, setCompanyID] = useState(props && props.location.pathname.slice('20'));

  const [countryArr, setCountryArr] = useState([]);
  const [stateArr, setStateArr] = useState([]);
  const [dataSourceArr, setDataSourceArr] = useState([]);
  const [loading, setLoading] = useState(false);

  const [company, setCompany] = useState({
    companyName: '',
    companyAddress: '',
    companySuburb: '',
    companyPostCode: '',
    countryID: '',
    stateID: '',
    companyWebsite: '',
    companyDataSourceIP: '',
    companyDataSourceUserName: '',
    companyDataSourcePwd: '',
    companyDataSourceTypeID: '',
    companyActive: false,

    udf1Label: '',
    udf2Label: '',
    udf3Label: '',
    udf4Label: '',
    udf5Label: '',

    udf1Enc: '',
    udf2Enc: '',
    udf3Enc: '',
    udf4Enc: '',
    udf5Enc: '',

    udf1Value: '',
    udf2Value: '',
    udf3Value: '',
    udf4Value: '',
    udf5Value: '',
  });

  useEffect(() => {
    getSingleCompany();
    getCountries();
    // getStates();
    getDataSource();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    companyID === ''
      ? httpClient
          .post(
            '/company',
            qs.stringify({
              companyName: company.companyName,
              companyAddress: company.companyAddress,
              companySuburb: company.companySuburb,
              companyPostCode: company.companyPostCode,
              countryID: company.countryID,
              stateIDd: company.stateID,
              companyWebsite: company.companyWebsite,
              // companyDataSourceIP: company.companyDataSourceIP,
              // companyDataSourceUserName: company.companyDataSourceUserName,
              // companyDataSourcePwd: company.companyDataSourcePwd,
              dataSourceUDF1Value: company.udf1Value,
              dataSourceUDF2Value: company.udf2Value,
              dataSourceUDF3Value: company.udf3Value,
              dataSourceUDF4Value: company.udf4Value,
              dataSourceUDF5Value: company.udf5Value,

              companyDataSourceTypeID: company.companyDataSourceTypeID,
              companyActive: company.companyActive === true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessageState('success');
              setMessage(data.message);
              setLoading(false);
              setCompany({
                companyName: '',
                companyAddress: '',
                companySuburb: '',
                companyPostCode: '',
                countryID: '',
                stateID: '',
                companyWebsite: '',
                companyDataSourceIP: '',
                companyDataSourceUserName: '',
                companyDataSourcePwd: '',
                companyDataSourceTypeID: '',
                companyActive: false,
              });
              setTimeout(() => {
                history.push('/company/listCompanies');
              }, 1000);
            } else {
              setOpen(true);
              // setMessage('Failed to Add Data Source');
              setMessageState('error');
              setMessage(data.message);
              setLoading(false);
            }
          })
      : httpClient
          .post(
            `/company/${companyID}`,
            qs.stringify({
              companyName: company.companyName,
              companyAddress: company.companyAddress,
              companySuburb: company.companySuburb,
              companyPostCode: company.companyPostCode,
              countryID: company.countryID,
              stateIDd: company.stateID,
              companyWebsite: company.companyWebsite,
              // companyDataSourceIP: company.companyDataSourceIP,
              // companyDataSourceUserName: company.companyDataSourceUserName,
              // companyDataSourcePwd: company.companyDataSourcePwd,

              dataSourceUDF1Value: company.udf1Value,
              dataSourceUDF2Value: company.udf2Value,
              dataSourceUDF3Value: company.udf3Value,
              dataSourceUDF4Value: company.udf4Value,
              dataSourceUDF5Value: company.udf5Value,

              companyDataSourceTypeID: company.companyDataSourceTypeID,
              companyActive: company.companyActive === true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessageState('success');
              setMessage(data.message);
              setLoading(false);
              setTimeout(() => {
                history.push('/company/listCompanies');
              }, 1000);
            } else {
              setOpen(true);
              setMessageState('error');
              setMessage(data.message);
              setLoading(false);
            }
          });
  };

  const getSingleCompany = () => {
    companyID &&
      httpClient.get(`/company/${companyID}`).then(({ data }) => {
        if (data.success === true) {
          setCompany({
            ...company,
            companyName: data.data.companyName,
            companyAddress: data.data.companyAddress,
            companySuburb: data.data.companySuburb,
            companyPostCode: data.data.companyPostCode,
            countryID: data.data.country.countryID,
            stateID: data.data.stateIDd.stateID,
            companyWebsite: data.data.companyWebsite,
            // companyDataSourceIP: data.data.companyDataSourceIP,
            // companyDataSourceUserName: data.data.companyDataSourceUserName,
            // companyDataSourcePwd: data.data.companyDataSourcePwd,
            companyDataSourceTypeID: data.data.companyDataSourceType.companyDataSourceTypeID,

            udf1Value: data.data.dataSourceUDF1Value,
            udf2Value: data.data.dataSourceUDF2Value,
            udf3Value: data.data.dataSourceUDF3Value,
            udf4Value: data.data.dataSourceUDF4Value,
            udf5Value: data.data.dataSourceUDF5Value,

            companyActive: data.data.companyActive === 1 ? true : false,
          });
        }
      });
  };

  const getCountries = () => {
    httpClient.get('country/all').then(({ data }) => {
      if (data.success === true) {
        setCountryArr(data.data);
      }
    });
  };

  const getStates = () => {
    // httpClient.get('state').then(({ data }) => {
    //   if (data.success === true) {
    //     setStateArr(data.data);
    //   }
    // });
  };

  const getDataSource = () => {
    var newArr = [];
    httpClient.get('data/source-type').then(({ data }) => {
      if (data.success === true) {
        newArr = data.data.filter(source => {
          return source.dataSourceTypeActive === 'Active';
        });
        setDataSourceArr(newArr);
      }
    });
  };

  console.log('data source array', dataSourceArr);

  dataSourceArr &&
    dataSourceArr.map(source => {
      if (source.dataSourceTypeID === company.companyDataSourceTypeID) {
        source.dataSourceUDF1Label != '' && (company.udf1Label = source.dataSourceUDF1Label);
        source.dataSourceUDF2Label != '' && (company.udf2Label = source.dataSourceUDF2Label);
        source.dataSourceUDF3Label != '' && (company.udf3Label = source.dataSourceUDF3Label);
        source.dataSourceUDF4Label != '' && (company.udf4Label = source.dataSourceUDF4Label);
        source.dataSourceUDF5Label != '' && (company.udf5Label = source.dataSourceUDF5Label);

        source.uDF1Encryption != '' && (company.udf1Enc = source.uDF1Encryption);
        source.uDF2Encryption != '' && (company.udf2Enc = source.uDF2Encryption);
        source.uDF3Encryption != '' && (company.udf3Enc = source.uDF3Encryption);
        source.uDF4Encryption != '' && (company.udf4Enc = source.uDF4Encryption);
        source.uDF5Encryption != '' && (company.udf5Enc = source.uDF5Encryption);
      }
    });

  console.log('company', company);

  const handleChange = e => {
    const { name, value } = e.target;
    setCompany({
      ...company,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setCompany({
      ...company,
      [name]: checked,
    });
  };

  const handleBack = () => {
    history.push('/company/listCompanies');
  };

  var newState = [];

  countryArr &&
    countryArr.map(count => {
      if (count.countryID === company.countryID) {
        return newState.push(count.state);
      }
    });

  return (
    <PageContainer heading={companyID === '' ? 'Add Company' : 'Edit Company'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={6}>
              <TextField
                required
                label="Company Name"
                variant="outlined"
                name="companyName"
                type="text"
                value={company.companyName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                required
                label="Address 1"
                variant="outlined"
                name="companyAddress"
                type="text"
                value={company.companyAddress}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                required
                label="Post Code"
                variant="outlined"
                name="companyPostCode"
                type="text"
                value={company.companyPostCode}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                required
                label="Suburb"
                variant="outlined"
                name="companySuburb"
                type="text"
                value={company.companySuburb}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Country</InputLabel>
                <Select value={company.countryID} label="Country" onChange={handleChange} name="countryID">
                  <MenuItem value="">
                    <em>Select Country</em>
                  </MenuItem>
                  {countryArr.map(con => {
                    return (
                      <MenuItem key={con.countryID} value={con.countryID}>
                        {con.countryName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>State</InputLabel>
                <Select
                  value={company.stateID}
                  label="State"
                  onChange={handleChange}
                  name="stateID"
                  disabled={newState.length > 0 ? false : true}>
                  <MenuItem value="">
                    <em>Select State</em>
                  </MenuItem>
                  {newState.length > 0 &&
                    newState[0].map(state => {
                      return (
                        <MenuItem key={state.stateID} value={state.stateID}>
                          {state.stateName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                type="text"
                label="Website"
                variant="outlined"
                name="companyWebsite"
                value={company.companyWebsite}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Data Source Type</InputLabel>
                <Select
                  value={company.companyDataSourceTypeID}
                  label="Data Source Type"
                  onChange={handleChange}
                  name="companyDataSourceTypeID">
                  <MenuItem value="">
                    <em>Select Data Source Type</em>
                  </MenuItem>
                  {dataSourceArr &&
                    dataSourceArr.map(data => {
                      return (
                        <MenuItem key={data.dataSourceTypeID} value={data.dataSourceTypeID}>
                          {data.dataSourceTypeName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            {/* <Grid item sm={12} md={6}>
              <TextField
                type="text"
                label="Data Source IP"
                variant="outlined"
                name="companyDataSourceIP"
                value={company.companyDataSourceIP}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                required
                type="text"
                label="Data Source User Name"
                variant="outlined"
                name="companyDataSourceUserName"
                value={company.companyDataSourceUserName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid> 

            {/* {companyID == "" && 
            <Grid item sm={12} md={6}>
              <TextField
                required
                type="password"
                label="Data Source Password"
                variant="outlined"
                name="companyDataSourcePwd"
                value={company.companyDataSourcePwd}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid> */}

            {company.udf1Label !== '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  label={company.udf1Label}
                  variant="outlined"
                  name="udf1Value"
                  type={company.udf1Enc != '1' ? 'text' : 'password'}
                  value={company.udf1Value}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {company.udf2Label !== '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  label={company.udf2Label}
                  variant="outlined"
                  name="udf2Value"
                  type={company.udf2Enc != '1' ? 'text' : 'password'}
                  value={company.udf2Value}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {company.udf3Label !== '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  label={company.udf3Label}
                  variant="outlined"
                  name="udf3Value"
                  type={company.udf3Enc != '1' ? 'text' : 'password'}
                  value={company.udf3Value}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {company.udf4Label !== '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  label={company.udf4Label}
                  variant="outlined"
                  name="udf4Value"
                  type={company.udf4Enc != '1' ? 'text' : 'password'}
                  value={company.udf4Value}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {company.udf5Label !== '' && (
              <Grid item sm={12} md={12}>
                <TextField
                  label={company.udf5Label}
                  variant="outlined"
                  name="udf5Value"
                  type={company.udf1Enc != '1' ? 'text' : 'password'}
                  value={company.udf5Value}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="companyActive"
                    checked={company.companyActive}
                    onChange={handleCheck}
                    value={company.companyActive}
                    color="primary"
                  />
                }
                label="Company Active?"
              />
            </Grid>

            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={loading ? true : false}>
                {companyID === '' ? 'Add Company' : 'Edit Company'}
              </Button>
              {loading && <CircularProgress size={25} style={{ marginLeft: '10px' }} />}
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        className={classes.snack}>
        <Alert onClose={handleClose} severity={messageState}>
          {messageState === "success" ?
            message
          : (message.length > 0 && message.map(msg => <p>{msg}</p>))
          }
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddCompany;
