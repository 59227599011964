import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Button,
  CircularProgress,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useRef, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
// import Dropzone from 'react-dropzone';
// import { AddPhotoAlternateOutlined, Close, Cancel } from '@material-ui/icons';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
  // uploadImage: {
  //   cursor: 'pointer',
  //   textAlign: 'center',
  //   padding: '40px 0',
  //   border: `1px solid #999`,
  //   borderRadius: '5px',
  //   '&:hover': {
  //     border: `1px solid #333`,
  //   },
  //   '& .MuiSvgIcon-root': {
  //     fontSize: '45px',
  //   },
  // },
  // imgClass: {
  //   height: '100%',
  //   width: '100%',
  //   objectFit: 'cover',
  // },
  // addMoreUDF: {
  //   margin: '15px',
  //   width: '100%',
  // },
  // addMore: {
  //   margin: '15px',
  //   width: '100%',
  //   position: 'relative',
  // },
  // arrayField: {
  //   width: '100%',
  //   height: '56px',
  // },
  // btnAddMore: {
  //   textAlign: 'right',
  //   marginRight: '15px',
  // },
  // header: {
  //   marginTop: '20px',
  // },
  // remove: {
  //   position: 'absolute',
  //   right: '50px',
  //   top: '5px',
  // },
  // box: {
  //   marginBottom: '20px',
  // },
  // place:{
  //   padding:"10px",
  //   position:"absolute",
  //   top:"45px",
  //   right:"50px",
  //   display: "flex"
  // },
  snack: {
    '& .MuiAlert-action': {
      alignItems: 'flex-start',
      paddingTop: '7px',
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddSyncFields = props => {
  const classes = useStyles();
  const ref = useRef();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState([]);
  const [messageState, setMessageState] = useState('');

  const [indexPlace, setIndexPlace] = useState('');

  const [syncID, setsyncID] = useState(props && props.location.pathname.slice(20));
  const [loading, setLoading] = useState(false);
  const [syncGroup, setSyncGroup] = useState([]);
  const [syncFields, setSyncFields] = useState({
    syncFieldSettingCode: '',
    syncFieldSettingName: '',
    syncFieldSettingGroupID: '',
    syncFieldSettingDescription: '',
    syncFieldSettingActive: false,
  });

  useEffect(() => {
    getSingleSyncField();
    getSyncGroups();
  }, []);

  const getSyncGroups = () => {
    httpClient.get('field-setting-group').then(({ data }) => {
      if (data.success == true) {
        setSyncGroup(data.data);
      }
    });
  };

  // console.log('sunc group', syncGroup);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    syncID === ''
      ? httpClient
          .post(
            '/field-setting',
            qs.stringify({
              syncFieldSettingCode: syncFields.syncFieldSettingCode,
              syncFieldSettingName: syncFields.syncFieldSettingName,
              syncFieldSettingGroupID: syncFields.syncFieldSettingGroupID,
              syncFieldSettingDescription: syncFields.syncFieldSettingDescription,
              syncFieldSettingActive: syncFields.syncFieldSettingActive == true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessageState('success');
              setMessage(data.message);
              setLoading(false);
              setSyncFields({
                syncFieldSettingCode: '',
                syncFieldSettingName: '',
                syncFieldSettingDescription: '',
                syncFieldSettingActive: false,
              });
              // setTimeout(() => {
              //   history.push('/sync/listSyncFields');
              // }, 1000);
            } else {
              setOpen(true);
              setMessageState('error');
              setMessage(data.message);
              setLoading(false);
            }
          })
      : httpClient
          .post(
            `/field-setting/${syncID}`,
            qs.stringify({
              syncFieldSettingCode: syncFields.syncFieldSettingCode,
              syncFieldSettingName: syncFields.syncFieldSettingName,
              syncFieldSettingGroupID: syncFields.syncFieldSettingGroupID,
              syncFieldSettingDescription: syncFields.syncFieldSettingDescription,
              syncFieldSettingActive: syncFields.syncFieldSettingActive == true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setOpen(true);
              setMessageState('success');
              setMessage(data.message);
              setLoading(false);
              // setTimeout(() => {
              //   history.push('/sync/listSyncFields');
              // }, 1000);
            } else {
              setOpen(true);
              setMessageState('error');
              setMessage(data.message);
              setLoading(false);
            }
          });
  };

  const getSingleSyncField = () => {
    syncID &&
      httpClient.get(`/field-setting/${syncID}`).then(({ data }) => {
        if (data.success === true) {
          setSyncFields({
            syncFieldSettingCode: data.data.syncFieldSettingCode,
            syncFieldSettingName: data.data.syncFieldSettingName,
            syncFieldSettingGroupID:
              data.data.syncFieldSettingGroup && data.data.syncFieldSettingGroup.syncFieldSettingGroupID,
            syncFieldSettingDescription: data.data.syncFieldSettingDescription,
            syncFieldSettingActive: data.data.syncFieldSettingActive == 1 ? true : false,
          });
        }
      });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    var newVal = '';
    if (name === 'syncFieldSettingCode') {
      // newVal = value.replace(/\s+/g, '_').toUpperCase();
      newVal = value.replace(/[&\/\\#, +()$~%.'":*?<>{}@!^]/g, '_').toUpperCase();
    }
    setSyncFields({
      ...syncFields,
      [name]: name === 'syncFieldSettingCode' ? newVal : value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setSyncFields({
      ...syncFields,
      [name]: checked,
    });
  };

  const handleBack = () => {
    history.push('/sync/listSyncFields');
  };

  return (
    <PageContainer heading={syncID === '' ? 'Add Sync Field' : 'Edit Sync Field'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required={syncID == '' ? true : false}
                disabled={syncID == '' ? false : true}
                label="Setting Code"
                variant="outlined"
                name="syncFieldSettingCode"
                type="text"
                value={syncFields.syncFieldSettingCode}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                type="text"
                label="Setting Name"
                variant="outlined"
                name="syncFieldSettingName"
                value={syncFields.syncFieldSettingName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Setting Group *</InputLabel>
                <Select
                  required
                  value={syncFields.syncFieldSettingGroupID}
                  label="Setting Group *"
                  onChange={handleChange}
                  name="syncFieldSettingGroupID">
                  <MenuItem value="">
                    <em>Select Setting Group</em>
                  </MenuItem>
                  {syncGroup &&
                    syncGroup.map(data => {
                      return (
                        <MenuItem key={data.settingsGroupID} value={data.settingsGroupID}>
                          {data.settingsGroupName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                required
                multiline
                rows={4}
                label="Setting Description"
                variant="outlined"
                name="syncFieldSettingDescription"
                type="text"
                value={syncFields.syncFieldSettingDescription}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="syncFieldSettingActive"
                    checked={syncFields.syncFieldSettingActive}
                    onChange={handleCheck}
                    value={syncFields.syncFieldSettingActive}
                    color="primary"
                  />
                }
                label="Setting Active?"
              />
            </Grid>

            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={loading ? true : false}>
                {syncID === '' ? 'Add Sync Field' : 'Edit Sync Field'}
              </Button>
              {loading && <CircularProgress size={25} style={{ marginLeft: '10px' }} />}
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        className={classes.snack}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {messageState === 'success' ? message : message.length > 0 && message.map(msg => <p>{msg}</p>)}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddSyncFields;
