import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { httpClient } from 'appUtility/Api';
import { Box, Card, CircularProgress, Grid, IconButton, makeStyles, Switch, TextField } from '@material-ui/core';
import AlertForm from '../AlertForm';
import { Close } from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertOptions from '../AlertOptions';

const qs = require('qs');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px',
    margin: '0 15%',
    [theme.breakpoints.down('md')]: {
      margin: '0',
    },
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '150px',
  },
  verify: {
    float: 'right',
  },
  text: {
    width: '100%',
    margin: '10px 0',
  },
  margin: {
    marginTop: '20px',
  },
  logoImg: {
    marginLeft: '40px',
  },
  integrationGrid: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '15px',
    border: '1px solid #cfcfcf',
    padding: '10px 15px',
    borderRadius: '5px',
  },
  imgClass: {
    width: '100%',
    height: '40px',
    objectFit: 'contain',
  },
  imgLogo: {
    height: '175px',
    maxWidth: '175px',
    objectFit: 'contain',
  },
  close: {
    position: 'absolute',
    right: '20px',
    top: '10px',
  },
  editClick: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.primary,
    marginRight: '5px',
  },
  optionText: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.primary,
  },
  snack: {
    '& .MuiAlert-action': {
      alignItems: 'flex-start',
      paddingTop: '7px',
    },
  },
}));

const AlertModuleIntegration = props => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState([]);
  const [messageState, setMessageState] = useState('');

  const [alertData, setAlertData] = useState({
    open: true,
    success: '',
  });

  const [moduleList, setModuleList] = useState([]);

  const [modulesID, setModulesID] = useState([]);

  const [modulesCheck, setModulesCheck] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getModules();
    getModuleIntegrationDetail();
  }, [props.companyData.companyID]);

  const getModuleIntegrationDetail = () => {
    httpClient
      .post(
        `https://erply.retailcare.com.au/Modules/listClientModuleDetail.php`,
        qs.stringify({
          //   action: 'get_detail',
          clientCode: props.companyData.dataSourceUDF1Value,
        }),
      )
      .then(({ data }) => {
        if (data.msg == 'Success') {
          var newdata = data.data;

          newdata.map(data => {
            //  allModules.push(data.moduleID);
            modulesCheck[data.moduleID] = true;
          });

          var allModules = [...new Set(newdata.map(item => item.moduleID))];
          setModulesID(allModules);

        } else {
        }
      });
  };

  console.log('modules idd>>>', modulesID);
  console.log('module checkkkkkk >>>', modulesCheck);

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getModules = () => {
    setLoading(true);
    props.companyData &&
      httpClient
        .post(
          'https://erply.retailcare.com.au/Modules/listModule.php',
          qs.stringify({
            action: 'get',
            clientCode: props.companyData.dataSourceUDF1Value,
          }),
        )
        .then(({ data }) => {
          if (data) {
            setModuleList(data.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
  };

  const handleChangeSwitch = (e, id) => {
    const { checked } = e.target;
    modulesCheck[id] = checked;
    if (checked === true) {
      setModulesCheck(modulesCheck);
      setModulesID([...modulesID, id]);
    } else {
      setModulesCheck(modulesCheck);
      const newMods = modulesID.filter(module => module !== id);
      setModulesID(newMods);
    }
  };

  const handleSubmitModule = () => {
    // console.log('childData', child);
    const source = {
      modules: modulesID,
    };
    httpClient
      .post(
        `https://erply.retailcare.com.au/Modules/addModuleClient.php`,
        qs.stringify({
          action: 'add',
          clientCode: props.companyData.dataSourceUDF1Value,
          source: source,
        }),
      )
      .then(({ data }) => {
        if (data.msg === 'Success') {
          setOpen(true);
          setMessageState('success');
          setMessage('Erply Modules updated successfully!');
          setTimeout(() => {
            Snack();
          }, 1500);
        } else {
          setOpen(true);
          setMessageState('error');
          setMessage(data.message);
        }
      });
  };

    console.log('props>>>', props);

  const Snack = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: false,
    });
  };

  props.sendModuleIntegrationData(alertData);

  return (
    <Dialog open={alertData.open} onClose={Snack} fullWidth={true} maxWidth={'lg'}>
      {!loading ? (
        <div>
          <IconButton onClick={Snack} className={classes.close}>
            <Close />
          </IconButton>
          <DialogTitle>
            <h2>{props.companyData.companyName} Module Integration</h2>
          </DialogTitle>
          <DialogContent>
            {/* <Grid container spacing={4}> */}
            {/* <Grid item xs={12} md={8}> */}
            <Card className={classes.root}>
              <h3>Integrated With {props.companyData.companyName}</h3>
              {moduleList &&
                moduleList.map(module => {
                  return (
                    <Box className={classes.integrationGrid}>
                      <div>{module.moduleName}</div>

                      <div>
                        <Switch
                          //   checked={modulesID.includes(module.moduleID)}
                          checked={modulesCheck[module.moduleID] ? true : false}
                          value={modulesCheck[module.moduleID] ? true : false}
                          onChange={e => handleChangeSwitch(e, module.moduleID)}
                        />
                      </div>
                    </Box>
                  );
                })}
              <Box textAlign={'right'} mt={5}>
                <Button color="primary" variant="contained" onClick={handleSubmitModule}>
                  Submit
                </Button>
              </Box>
            </Card>

            {/* </Grid> */}
            {/* </Grid> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={Snack} color="primary">
              Cancel
            </Button>
            {/* <Button onClick={handleDisable} color="secondary" autoFocus>
          Yes
        </Button> */}
          </DialogActions>
        </div>
      ) : (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={messageState}>
          {messageState === 'success' ? message : message.length > 0 && message.map(msg => <p>{msg}</p>)}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default AlertModuleIntegration;
