import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { history } from 'redux/store';
const qs = require('qs');

const useStyles = makeStyles({
  text: {
    width: '100%',
    margin: '10px',
  },
  root: {
    padding: '40px',
  },
  snack:{
    "& .MuiAlert-action":{
      alignItems: "flex-start",
      paddingTop:"7px"
    }
  }
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddCountry = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState([]);
  const [messageState, setMessageState] = useState('');
  const [countryID, setCountryID] = useState(props && props.location.pathname.slice(20));
  const [loading, setLoading] = useState(false);

  const [countryDetails, setCountryDetails] = useState({
    countryName: '',
    shortName: '',
    countryActive: false,
  });

  useEffect(() => {
    getSingleCountry();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setCountryDetails({
      ...countryDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setCountryDetails({
      ...countryDetails,
      [name]: checked,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    countryID === ''
      ? httpClient
          .post(
            'country',
            qs.stringify({
              countryName: countryDetails.countryName,
              shortName: countryDetails.shortName,
              countryActive: countryDetails.countryActive === true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setLoading(false);
              setOpen(true);
              setMessageState('success');
              setMessage(data.message);
              setCountryDetails({
                countryName: '',
                shortName: '',
                countryActive: '',
              });
            } else {
              setLoading(false);
              setOpen(true);
              setMessageState('error');
              setMessage(data.message);
            }
          })
      : httpClient
          .post(
            `country/${countryID}`,
            qs.stringify({
              countryName: countryDetails.countryName,
              shortName: countryDetails.shortName,
              countryActive: countryDetails.countryActive === true ? 1 : 0,
            }),
          )
          .then(({ data }) => {
            if (data.success === true) {
              setLoading(false);
              setOpen(true);
              setMessageState('success');
              setMessage(data.message);
              history.push('/country/listCountry');
            } else {
              setLoading(false);
              setOpen(true);
              setMessageState('error');
              setMessage(data.message);
            }
          });
  };

  const getSingleCountry = () => {
    countryID &&
      httpClient.get(`/country/${countryID}`).then(({ data }) => {
        if (data.success === true) {
            setCountryDetails({
              countryName: data.data.countryName,
              countryActive: data.data.countryActive === "Active" ? true : false,
              shortName: data.data.shortName,
            });
        }
      });
  };

  const handleBack = () => {
    history.push('/country/listCountry');
  };

  return (
    <PageContainer heading={countryID === '' ? 'Add Country' : 'Edit Country'}>
      <Card className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Country Name"
                variant="outlined"
                name="countryName"
                value={countryDetails.countryName}
                // error={countryError}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                label="Country Short Name"
                variant="outlined"
                name="shortName"
                value={countryDetails.shortName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="countryActive"
                    checked={countryDetails.countryActive}
                    onChange={handleCheck}
                    value={countryDetails.countryActive}
                    color="primary"
                  />
                }
                label="Make Country Active"
              />
            </Grid>
            <Grid item sm={12}>
              <Box textAlign="right" pt={4}>
                <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit" disabled={loading ? true : false}>
                  {countryID === '' ? 'Add Country' : 'Edit Country'}
                </Button>
                {loading && <CircularProgress size={25} />}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
        {messageState === "success" ?
            message
          : (message.length > 0 && message.map(msg => <p>{msg}</p>))
          }
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddCountry;
