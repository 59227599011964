export const marketingData = [
  {
    id: 1,
    name: 'Facebook Ads',
    desc: '63 Likes, 387 Shares',
    icon: '/images/dashboard/icon-facebook.png',
    color: 'bg-indigo lighten-1',
    budget: 570,
    growth: 20,
  },

  {
    id: 2,
    name: 'Twitter Ads',
    desc: '43 Likes, 545 Shares',
    icon: '/images/dashboard/icon-twitter.png',
    color: 'bg-light-blue accent-2',
    budget: 811,
    growth: -5,
  },

  {
    id: 3,
    name: 'Instagram',
    desc: '83 Follows, 79 Likes',
    icon: '/images/dashboard/icon-instagram.png',
    color: 'bg-pink accent-3',
    budget: 685,
    growth: 20,
  },

  {
    id: 3,
    name: 'Google Ads',
    desc: '63 Likes, 387 Shares',
    icon: '/images/dashboard/icon-google-ad.png',
    color: 'bg-pink accent-3',
    budget: 685,
    growth: 145,
  },
];

export const latestPostList = [
  {
    image: 'https://via.placeholder.com/134x100',
    title: '5 DIY tips to use in kitchen',
    description: 'There are many variations of passages of Lorem Ipsum...',
    date: '28 Oct, 2016',
  },

  {
    image: 'https://via.placeholder.com/134x100',
    title: 'Flowers which keep you healthy',
    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit...',
    date: '27 Feb, 2017',
  },
  {
    image: 'https://via.placeholder.com/134x100',
    title: 'Top 5 beaches in the world',
    description: 'There are many variations of passages of Lorem Ipsum......',
    date: '24 Feb, 2017',
  },
];

export const countryList = [
  {
    name: 'United State',
    flagCode: 'us',
    visitors: '12,455',
    badgeColor: '#D72934',
  },
  { name: 'France', flagCode: 'fr', visitors: '445', badgeColor: '#3F51B5' },
  { name: 'Germany', flagCode: 'gm', visitors: '36,855', badgeColor: '#FFA005' },
  { name: 'Spain', flagCode: 'es', visitors: '9,877', badgeColor: '#5D9405' },
];

export const userDetails = {
  id: 123,
  name: 'Domnic Harris',
  profile_pic: 'https://via.placeholder.com/150x150',
  job_title: 'Co-Founder & CEO',
  bio: 'Nam imperdiet ornare enim ac tempor Suspendisse ac accumsan orci jomnic dr neva ketoli respecotra domeko...',
  badge: '/images/dashboard/intranet/badge.png',
  stats: {
    followers: 457,
    courses: 12,
    following: 697,
  },
};
