
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import MuiAlert from '@material-ui/lab/Alert';
import TableContactComponent from './../TableContactComponent';
import AlertDialogContact from './../AlertDialogContact';
import AlertDialogEnable from './../AlertDialogEnable';
import { Close } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddContact from '../AddContact';
import AlertReset from '../AlertReset';

const columns = [
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'lastName' },
  { id: 'position', label: 'Position' },
  { id: 'email_username', label: 'contact Name' },
  { id: 'primaryContact', label: 'Primary Contact' },
  { id: 'hasAccessToAdminPanel', label: 'Access to Admin Panel' },
  { id: 'contactActive', label: 'Active' },
  { id: 'actions', label: 'Actions' },
];

// const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ListContact = props => {
  const [contact, setContact] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [activateTable, setActivateTable] = useState("");
  const [enableTable, setEnableTable] = useState("");
  const [productData, setProductData] = useState({});

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [openActivateDialog, setOpenActivateDialog] = useState(false);
  const [openEnableDialog, setOpenEnableDialog] = useState(false);
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);


  const [activeID, setActiveID] = useState('');
  const [activeStatus, setActiveStatus] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [companyID, setCompanyID] = useState(props && props.companyID);
  const [rowsPerPage, setRowsPerPage] = useState(
    // configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
    20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(20);
  //   const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);
  const [alertData, setAlertData] = useState({
    open: true,
    success: '',
  });

  props.returnViewData(alertData);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  //   const [submittedData, setSubmittedData] = useState({
  //     submit: false,
  //     contactActive: '',
  //     state: '',
  //     search: '',
  //   });
  //   const [filterData, setFilterData] = useState({
  //     contactActive: '',
  //     state: '',
  //     search: '',
  //     removed: '',
  //   });

  //   useEffect(() => {
  //     if (filterData.contactActive === '' && filterData.state === '' && filterData.search === '') {
  //       setSubmittedData({
  //         ...submittedData,
  //         submit: false,
  //       });
  //     }
  //     if (filterData.contactActive === ' ') filterData.contactActive = '';
  //     if (filterData.state === ' ') filterData.state = '';
  //     if (filterData.search === ' ') filterData.search = '';

  //     filterData.removed === true && handleSubmitData();
  //   }, [filterData]);

  useEffect(() => {
    getContacts();
  }, []);

  //   useEffect(() => {
  //     let contactStorage = JSON.parse(localStorage.getItem('contact_filter'));
  //     contactStorage !== null && setFilterData(contactStorage);

  //     contactStorage == null
  //       ? getcontacts()
  //       : contactStorage.contactActive == '' &&
  //         contactStorage.state == '' &&
  //         contactStorage.search == '' &&
  //         contactStorage.removed == false
  //       ? getcontacts()
  //       : console.log('contact');
  //   }, []);

  //   const handleSubmitData = () => {
  //     setSubmittedData({
  //       contactActive: filterData.contactActive,
  //       state: filterData.state,
  //       search: filterData.search,
  //       submit: true,
  //     });
  //     filterData.removed = true;
  //     localStorage.setItem('contact_filter', JSON.stringify(filterData));
  //     setLoading(true);
  //     if (filterData.contactActive || filterData.state || filterData.search) {
  //       httpClient
  //         .get(`contact/filter?q=${filterData.search}&contactActive=${filterData.contactActive}&state=${filterData.state}`)
  //         .then(({ data }) => {
  //           if (data.success === true) {
  //             setContact(data.data);
  //             setTotal(data.meta.total);
  //             // setRowsPerPage(data.meta.per_page);
  //             setPage(data.meta.current_page);
  //             setFrom(data.meta.from);
  //             setTo(data.meta.to);
  //             setLoading(false);
  //           }
  //         });
  //     } else {
  //       getcontacts();
  //     }
  //   };

  //   const handleFilter = e => {
  //     e.preventDefault();
  //     handleSubmitData();
  //   };

  const getContacts = () => {
    setLoading(true);
    httpClient.get(`company-contact/ByCompany/${companyID}?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data) {
        // let newData = [];
        // data.data.map(con => {
        //   if (con.companyID.companyID == companyID) {
        //     return newData.push(con);
        //   }
        // });
        setContact(data.data);
        setTotal(data.meta.total);
        // setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  //   const handleChange = e => {
  //     const { name, value } = e.target;
  //     setFilterData({
  //       ...filterData,
  //       [name]: value,
  //       removed: false,
  //     });
  //   };

  //   const handleRemove = data => {
  //     setFilterData({
  //       ...filterData,
  //       [data]: '',
  //       removed: true,
  //     });
  //     setSubmittedData({
  //       ...submittedData,
  //       [data]: '',
  //     });
  //   };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    // console.log('direction', direction);
    setCurrentColumn(column);
    httpClient
      .get(
        `company-contact/ByCompany/${companyID}?sort_by=${column.id}&direction=${
          direction ? 'asc' : 'desc'
        }&pagination=${rowsPerPage}`,
      )
      .then(({ data }) => {
        if (data.success === true) {
          //   let newData = [];
          //   data.data.map(con => {
          //     if (con.companyID.companyID == companyID) {
          //       return newData.push(con);
          //     }
          //   });
          setContact(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    // submittedData.submit
    //   ? httpClient
    //       .get(
    //         `contact/filter?q=${filterData.search}&contactActive=${filterData.contactActive}&state=${filterData.state}&pagination=${rowsPerPage}&page=${page}`,
    //       )
    //       .then(({ data }) => {
    //         if (data.success === true) {
    //           setContact(data.data);
    //           setTotal(data.meta.total);
    //           setRowsPerPage(parseInt(data.meta.per_page));
    //           setPage(data.meta.current_page);
    //           setFrom(data.meta.from);
    //           setTo(data.meta.to);
    //           setLoading(false);
    //         }
    //       })
    //   :

    httpClient
      .get(
        `company-contact/ByCompany/${companyID}?direction=desc&pagination=${rowsPerPage}&page=${page}`,
      )
      .then(({ data }) => {
        if (data) {
          // let newData = [];
          // data.data.map(con => {
          //   if (con.companyID.companyID == companyID) {
          //     return newData.push(con);
          //   }
          // });
          setContact(data.data);
          setTotal(data.meta.total);
          setRowsPerPage(parseInt(data.meta.per_page));
          setPage(data.meta.current_page);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setLoading(false);
        }
      });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    // localStorage.setItem('configRowPerPage', event.target.value);
    // submittedData.submit
    //   ? httpClient
    //       .get(
    //         `contact/filter?q=${filterData.search}&contactActive=${filterData.contactActive}&state=${
    //           filterData.state
    //         }&pagination=${+event.target.value}&page=${1}`,
    //       )
    //       .then(({ data }) => {
    //         setLoading(true);
    //         if (data.success === true) {
    //           setContact(data.data);
    //           setTotal(data.meta.total);
    //           setRowsPerPage(parseInt(data.meta.per_page));
    //           setFrom(data.meta.from);
    //           setTo(data.meta.to);
    //           setPage(data.meta.current_page);
    //           setLoading(false);
    //         }
    //       })
    //   :

    httpClient
      .get(
        `company-contact/ByCompany/${companyID}?direction=desc&pagination=${+event.target.value}&page=${1}`,
      )
      .then(({ data }) => {
        setLoading(true);
        if (data) {
          // let newData = [];
          // data.data.map(con => {
          //   if (con.companyID.companyID == companyID) {
          //     return newData.push(con);
          //   }
          // });
          setContact(data.data);
          // setContact(newData);
          setTotal(data.meta.total);
          setRowsPerPage(parseInt(data.meta.per_page));
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setPage(data.meta.current_page);
          setLoading(false);
        }
      });
  };

  const openAddContact = (id) => {
    setOpenContactDialog(true);
    setActiveID("");
  }

  const handleEdit = val => {
    // history.push(`/company/addContact/${val.memID}`);
    setOpenContactDialog(true);
    setActiveID(val.memID);
  };

  const returnContact = child => {
    child.open === false && setOpenContactDialog(false);
    child.open === false && getContacts();
  };

  const handleActivate = val => {
    setOpenActivateDialog(true);
    setActiveID(val.memID);
    setActiveStatus('Activate');
  };

  const handleDeactivate = val => {
    setOpenActivateDialog(true);
    setActiveID(val.memID);
    setActiveStatus('Deactivate');
  };

  const handleDisableAccess = val => {
    setOpenEnableDialog(true);
    setActiveID(val.memID);
    setActiveStatus('Disable');
  };

  const handleEnableAccess = val => {
    setOpenEnableDialog(true);
    setActiveID(val.memID);
    setActiveStatus('Enable');
  };

  const sendDataActivate = child => {
    child.open === false && setOpenActivateDialog(false);
    if (child.success === true) {
      httpClient.get(`company-contact/activate-deactivate/${activeID}`).then(({ data }) => {
        if (data.success === true) {
          setOpen(true);
          setMessage(data.message);
          setMessageState('success');
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
          // if (!activateTable) setActivateTable(true);
          // else setActivateTable(false);
          // activeStatus === "Activate" ? setActivateTable("handleActivate") : setActivateTable("handleDeactivate");
          setProductData(data.data);
          // getContacts();
        } else {
          setOpen(true);
          setMessage(`Failed to ${activeStatus} contact`);
          setMessageState('error');
        }
      });
    }
  };

  const sendDataEnable = child => {
    child.open === false && setOpenEnableDialog(false);
    if (child.success === true) {
      httpClient.get(`company-contact/enable-disable-adminAccess/${activeID}`).then(({ data }) => {
        if (data.success === true) {
          setOpen(true);
          setMessage(data.message);
          setMessageState('success');
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
          // activeStatus === "Enable" ? setEnableTable("handleEnable") : setEnableTable("handleDisable");
          // getContacts();
          setProductData(data.data)
        } else {
          setOpen(true);
          setMessage(`Failed to ${activeStatus} admin access`);
          setMessageState('error');
        }
      });
    }
  };

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getcontacts();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`contact/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setContact(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  const handleCloseAlert = () => {
    setAlertData({
      ...alertData,
      open: false,
      success: false,
    });
  };

  const handleResetPassword = (val) => {
    setActiveID(val.memID);
    setOpenResetDialog(true);
  }

  const sendResetData = (call) => {
    call.open === false && setOpenResetDialog(false);
  } 


  return (
    <Dialog open={alertData.open} onClose={handleCloseAlert} maxWidth="lg" fullWidth={true}>
      <DialogTitle>
        <h2>List Contact</h2>
        <IconButton onClick={handleCloseAlert} style={{ position: 'absolute', right: '20px', top: '10px' }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <TableContactComponent
        columns={columns}
        selectedProducts={contact}
        // title="List Contact"
        handleEdit={handleEdit}
        handleActivate={handleActivate}
        handleDeactivate={handleDeactivate}
        handleDisableAccess={handleDisableAccess}
        handleEnableAccess={handleEnableAccess}
        handleResetPassword={handleResetPassword}
        // onChangeSearch={e => onChangeSearch(e)}
        // searchValue={searchText}
        // onKeyPress={e => handleSearch(e)}
        // onKeyDown={e => handleDefault(e)}
        loading={loading}
        handleSort={handleSort}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        total={total}
        fromTable={from}
        toTable={to}
        rowsPerPage={rowsPerPage}
        companyID={companyID}
        //   handleFilter={handleFilter}
        //   filterData={filterData}
        //   submittedData={submittedData}
        //   handleChange={handleChange}
        //   handleRemove={handleRemove}
        direction={direction}
        currentColumn={currentColumn}
        activateTable={activateTable}
        enableTable={enableTable}
        productData={productData}
        openAddContact={openAddContact}
      />

      {openActivateDialog && <AlertDialogContact sendDataActivate={sendDataActivate} activeStatus={activeStatus} />}

      {openEnableDialog && <AlertDialogEnable sendDataEnable={sendDataEnable} activeStatus={activeStatus} />}

      {openContactDialog && <AddContact activeID={activeID} companyID={companyID} returnContact={returnContact} />}


      {openResetDialog && <AlertReset activeID={activeID} sendResetData={sendResetData} /> }

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default ListContact;
