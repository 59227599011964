import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import AlertDialog from '../AlertDialog';
import TableComponent from './../TableComponent';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertDelete from '../AlertDelete';

const columns = [
  { id: 'integrationName', label: 'Name' },
  { id: 'integrationImage', label: 'Image' },
  { id: 'integrationHelpURL', label: 'Help Link' },
  { id: 'integrationNotes', label: 'Notes' },
  { id: 'integrationAddedDate', label: 'Added Date' },

  { id: 'integrationActive', label: 'Active' },
  { id: 'actions', label: 'Actions' },
];

// const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ListIntegration = () => {
  const [integration, setIntegration] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [activateTable, setActivateTable] = useState("");

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    // configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
    20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  //   const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);
  const [to, setTo] = useState(20);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [selectID, setSelectID] = useState('');
  const [activeStatus, setActiveStatus] = useState('');
  const [productData, setProductData] = useState([]);
  const [deleteData, setDeleteData] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  //   const [submittedData, setSubmittedData] = useState({
  //     submit: false,
  //     stateActive: '',
  //     state: '',
  //     search: '',
  //   });
  //   const [filterData, setFilterData] = useState({
  //     stateActive: '',
  //     state: '',
  //     search: '',
  //     removed: '',
  //   });

  //   useEffect(() => {
  //     if (filterData.stateActive === '' && filterData.state === '' && filterData.search === '') {
  //       setSubmittedData({
  //         ...submittedData,
  //         submit: false,
  //       });
  //     }
  //     if (filterData.stateActive === ' ') filterData.stateActive = '';
  //     if (filterData.state === ' ') filterData.state = '';
  //     if (filterData.search === ' ') filterData.search = '';

  //     filterData.removed === true && handleSubmitData();
  //   }, [filterData]);

  useEffect(() => {
    getIntegrations();
  }, []);

  //   useEffect(() => {
  //     let stateStorage = JSON.parse(localStorage.getItem('state_filter'));
  //     stateStorage !== null && setFilterData(stateStorage);

  //     stateStorage == null
  //       ? getIntegrations()
  //       : stateStorage.stateActive == '' &&
  //         stateStorage.state == '' &&
  //         stateStorage.search == '' &&
  //         stateStorage.removed == false
  //       ? getIntegrations()
  //       : console.log('state');
  //   }, []);

  //   const handleSubmitData = () => {
  //     setSubmittedData({
  //       stateActive: filterData.stateActive,
  //       state: filterData.state,
  //       search: filterData.search,
  //       submit: true,
  //     });
  //     filterData.removed = true;
  //     localStorage.setItem('state_filter', JSON.stringify(filterData));
  //     setLoading(true);
  //     if (filterData.stateActive || filterData.state || filterData.search) {
  //       httpClient
  //         .get(`state/filter?q=${filterData.search}&stateActive=${filterData.stateActive}&state=${filterData.state}`)
  //         .then(({ data }) => {
  //           if (data.success === true) {
  //             setIntegration(data.data);
  //             setTotal(data.meta.total);
  //             // setRowsPerPage(data.meta.per_page);
  //             setPage(data.meta.current_page);
  //             setFrom(data.meta.from);
  //             setTo(data.meta.to);
  //             setLoading(false);
  //           }
  //         });
  //     } else {
  //       getIntegrations();
  //     }
  //   };

  //   const handleFilter = e => {
  //     e.preventDefault();
  //     handleSubmitData();
  //   };

  const getIntegrations = () => {
    setLoading(true);
    httpClient.get(`integration-type?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setIntegration(data.data);
        setTotal(data.meta.total);
        // setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  //   const handleChange = e => {
  //     const { name, value } = e.target;
  //     setFilterData({
  //       ...filterData,
  //       [name]: value,
  //       removed: false,
  //     });
  //   };

  //   const handleRemove = data => {
  //     setFilterData({
  //       ...filterData,
  //       [data]: '',
  //       removed: true,
  //     });
  //     setSubmittedData({
  //       ...submittedData,
  //       [data]: '',
  //     });
  //   };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    // console.log('direction', direction);
    setCurrentColumn(column);
    httpClient
      .get(`integration-type?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setIntegration(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    // submittedData.submit
    //   ? httpClient
    //       .get(
    //         `state/filter?q=${filterData.search}&stateActive=${filterData.stateActive}&state=${filterData.state}&pagination=${rowsPerPage}&page=${page}`,
    //       )
    //       .then(({ data }) => {
    //         if (data.success === true) {
    //           setIntegration(data.data);
    //           setTotal(data.meta.total);
    //           setRowsPerPage(parseInt(data.meta.per_page));
    //           setPage(data.meta.current_page);
    //           setFrom(data.meta.from);
    //           setTo(data.meta.to);
    //           setLoading(false);
    //         }
    //       })
    //   :

    httpClient
      .get(
        `integration-type?direction=desc&pagination=${rowsPerPage}&page=${page}`,
      )
      .then(({ data }) => {
        if (data.success === true) {
          setIntegration(data.data);
          setTotal(data.meta.total);
          setRowsPerPage(parseInt(data.meta.per_page));
          setPage(data.meta.current_page);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setLoading(false);
        }
      });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    // localStorage.setItem('configRowPerPage', event.target.value);
    // submittedData.submit
    //   ? httpClient
    //       .get(
    //         `state/filter?q=${filterData.search}&stateActive=${filterData.stateActive}&state=${
    //           filterData.state
    //         }&pagination=${+event.target.value}&page=${1}`,
    //       )
    //       .then(({ data }) => {
    //         setLoading(true);
    //         if (data.success === true) {
    //           setIntegration(data.data);
    //           setTotal(data.meta.total);
    //           setRowsPerPage(parseInt(data.meta.per_page));
    //           setFrom(data.meta.from);
    //           setTo(data.meta.to);
    //           setPage(data.meta.current_page);
    //           setLoading(false);
    //         }
    //       })
    //   :

    httpClient
      .get(
        `integration-type?direction=desc&pagination=${+event.target.value}&page=${1}`,
      )
      .then(({ data }) => {
        setLoading(true);
        if (data.success === true) {
          setIntegration(data.data);
          setTotal(data.meta.total);
          setRowsPerPage(parseInt(data.meta.per_page));
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setPage(data.meta.current_page);
          setLoading(false);
        }
      });
  };

  const handleEdit = val => {
    history.push(`/integration/addIntegration/${val.memID}`);
  };

  const handleActivate = val => {
    setOpenDialog(true);
    setSelectID(val.memID);
    setActiveStatus('Activate');
  };

  const handleDeactivate = val => {
    setOpenDialog(true);
    setSelectID(val.memID);
    setActiveStatus('Deactivate');
  };

  const sendDeactivate = child => {
    child.open == false && setOpenDialog(false);
    if (child.success === true) {
      httpClient.post(`integration-type/activate-deactivate/${selectID}`).then(({ data }) => {
        if (data.success === true) {
          setOpen(true);
          setMessage(data.message);
          setMessageState('success');
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1500);
          // activeStatus === "Activate" ? setActivateTable("handleActivate") : setActivateTable("handleDeactivate");
          // getIntegrations();
          setProductData(data.data);
        } else {
          setOpen(true);
          setMessage('Failed to Update Integration');
          setMessageState('error');
        }
      });
    }
  };

  const handleDelete = (val) => {
    setSelectID(val.memID);
    setOpenDeleteDialog(true);
  }

  const sendDelete = (child) => {
    child.open === false && setOpenDeleteDialog(false);
    if(child.success === true){
      httpClient.delete(`/integration-type/${selectID}`).then(({data}) => {
        if(data.success){
          setOpen(true);
          setMessage(data.message);
          setMessageState('success');
          getIntegrations();
          // setDeleteData(data.data);
        }else{
          setOpen(true);
          setMessage('Failed to Delete Integration');
          setMessageState('error');
        }
      })
    }
  }

  // const onChangeSearch = e => {
  //   setSearchText(e.target.value);
  //   e.target.value === '' && getstates();
  // };
  // const handleSearch = e => {
  //   if (e.key === 'Enter') {
  //     httpClient.get(`state/search?q=${searchText}`).then(({ data }) => {
  //       if (data.success === true) {
  //         setIntegration(data.data);
  //       } else {
  //         console.log('search failed');
  //       }
  //     });
  //   }
  // };

  // const handleDefault = e => {
  //   setSearchText(e.target.value);
  // };

  return (
    <PageContainer heading="Integration">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableComponent
              columns={columns}
              selectedProducts={integration}
              title="List Integration"
              handleEdit={handleEdit}
              handleActivate={handleActivate}
              handleDeactivate={handleDeactivate}
              handleDelete={handleDelete}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              //   handleFilter={handleFilter}
              //   filterData={filterData}
              //   submittedData={submittedData}
              //   handleChange={handleChange}
              //   handleRemove={handleRemove}
              direction={direction}
              currentColumn={currentColumn}
              activateTable={activateTable}
              productData={productData}
              deleteData={deleteData}
            />
          </Box>
        </Grid>
      </GridContainer>
      {openDialog && <AlertDialog sendDeactivate={sendDeactivate} activeStatus={activeStatus} />}

      {openDeleteDialog && <AlertDelete sendDelete={sendDelete} />  }

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default ListIntegration;
