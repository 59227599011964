import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  makeStyles,
  Card,
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Button,
  CircularProgress,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { history } from 'redux/store';
import Dropzone from 'react-dropzone';
import { AddPhotoAlternateOutlined, Close, Cancel } from '@material-ui/icons';
import blankLogo from './../../../utils/images/user_placeholder.png';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
  uploadImage: {
    cursor: 'pointer',
    textAlign: 'center',
    padding: '40px 0',
    border: `1px solid #999`,
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid #333`,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '45px',
    },
  },
  imgClass: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  addMoreUDF: {
    margin: '15px',
    width: '100%',
  },
  addMore: {
    margin: '15px',
    width: '100%',
    position: 'relative',
  },
  arrayField: {
    width: '100%',
    height: '56px',
  },
  btnAddMore: {
    textAlign: 'right',
    marginRight: '15px',
  },
  header: {
    marginTop: '20px',
  },
  remove: {
    position: 'absolute',
    right: '50px',
    top: '5px',
  },
  box: {
    marginBottom: '20px',
  },
  place: {
    padding: '10px',
    position: 'absolute',
    top: '45px',
    right: '50px',
    display: 'flex',
  },
  snack: {
    '& .MuiAlert-action': {
      alignItems: 'flex-start',
      paddingTop: '7px',
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddDataSource = props => {
  const classes = useStyles();
  const ref = useRef();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState([]);
  const [messageState, setMessageState] = useState('');

  const [indexPlace, setIndexPlace] = useState('');

  const [dataID, setDataID] = useState(props && props.location.pathname.slice(26));
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataSource, setDataSource] = useState({
    typeName: '',
    logo: '',
    helpLink: '',
    notes: '',
    typeActive: false,
  });

  const [fieldDetails, setFieldDetails] = useState({
    udf: [''],
    encription: [''],
  });

  useEffect(() => {
    getSingleDataSource();
  }, []);

  useEffect(() => {
    document.addEventListener('click', closeIndex, true);
    return () => {
      document.removeEventListener('click', closeIndex, true);
    };
  }, [indexPlace]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append('dataSourceTypeName', dataSource.typeName);
    formData.append('dataSourceTypeHelpLink', dataSource.helpLink);
    formData.append('dataSourceTypeHelpNotes', dataSource.notes);
    formData.append('dataSourceTypeActive', dataSource.typeActive === true ? 1 : 0);
    formData.append('dataSourceTypeLogo', dataSource.logo.path ? dataSource.logo : ' ');
    fieldDetails.udf.map((field, index) => {
      formData.append(`dataSourceUDF` + Math.ceil(index + 1) + `Label`, field);
    });

    fieldDetails.encription.map((field, index) => {
      formData.append(`uDF` + Math.ceil(index + 1) + `Encryption`, field == true ? 1 : 0);
    });

    dataID === ''
      ? httpClient.post('/data/source-type', formData).then(({ data }) => {
          if (data.success === true) {
            setOpen(true);
            setMessageState('success');
            setMessage(data.message);
            setLoading(false);
            setDataSource({
              typeName: '',
              logo: '',
              helpLink: '',
              notes: '',
              typeActive: false,
            });
            setTimeout(() => {
              history.push('/dataSource/listDataSource');
            }, 1000);
          } else {
            setOpen(true);
            setMessageState('error');
            setMessage(data.message);
            setLoading(false);
          }
        })
      : httpClient.post(`/data/source-type/${dataID}`, formData).then(({ data }) => {
          if (data.success === true) {
            setOpen(true);
            setMessageState('success');
            setMessage(data.message);
            setLoading(false);
            setTimeout(() => {
              history.push('/dataSource/listDataSource');
            }, 1000);
          } else {
            setOpen(true);
            setMessageState('error');
            setMessage(data.message);
            setLoading(false);
          }
        });
  };

  const getSingleDataSource = () => {
    dataID &&
      httpClient.get(`/data/source-type/${dataID}`).then(({ data }) => {
        if (data.success === true) {
          setDataSource({
            typeName: data.data.dataSourceTypeName,
            logo: data.data.dataSourceTypeLogo,
            helpLink: data.data.dataSourceTypeHelpLink,
            notes: data.data.dataSourceTypeHelpNotes,
            typeActive: data.data.dataSourceTypeActive === 'Active' ? true : false,
          });
          var udfData = [];
          var encData = [];
          fieldDetails.udf.map((u, index) => {
            data.data.dataSourceUDF1Label != '' && udfData.push(data.data.dataSourceUDF1Label);
            data.data.dataSourceUDF2Label != '' && udfData.push(data.data.dataSourceUDF2Label);
            data.data.dataSourceUDF3Label != '' && udfData.push(data.data.dataSourceUDF3Label);
            data.data.dataSourceUDF4Label != '' && udfData.push(data.data.dataSourceUDF4Label);
            data.data.dataSourceUDF5Label != '' && udfData.push(data.data.dataSourceUDF5Label);
          });

          fieldDetails.encription.map((u, index) => {
            encData.push(data.data.uDF1Encryption);
            encData.push(data.data.uDF2Encryption);
            encData.push(data.data.uDF3Encryption);
            encData.push(data.data.uDF4Encryption);
            encData.push(data.data.uDF5Encryption);
          });

          setFieldDetails({
            udf: udfData,
            encription: encData,
          });
        }
      });
  };

  // console.log('data source field', fieldDetails);

  const handleChange = e => {
    const { name, value } = e.target;
    setDataSource({
      ...dataSource,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setDataSource({
      ...dataSource,
      [name]: checked,
    });
  };

  const onDrop = file => {
    // return file[0];
    setDataSource({
      ...dataSource,
      // Image: (URL.createObjectURL(file[0]))
      logo: file[0],
    });
  };

  const handleBack = () => {
    history.push('/dataSource/listDataSource');
  };

  const handleChangeUDF = (e, index) => {
    const { name, value } = e.target;
    fieldDetails.udf[index] = value;
    setFieldDetails({
      ...fieldDetails,
      udf: fieldDetails.udf,
    });
  };

  const handleEncription = (e, index) => {
    const { name, checked } = e.target;
    fieldDetails.encription[index] = checked;
    setFieldDetails({
      ...fieldDetails,
      encription: fieldDetails.encription,
    });
  };

  const handleAdd = () => {
    setFieldDetails({
      ...fieldDetails,
      udf: [...fieldDetails.udf, ''],
      encription: [...fieldDetails.encription, ''],
    });
  };

  const handleRemove = (e, index) => {
    fieldDetails.udf.splice(index, 1);
    fieldDetails.encription.splice(index, 1);
    setFieldDetails({
      ...fieldDetails,
      udf: fieldDetails.udf,
      encription: fieldDetails.encription,
    });
  };

  const handleRemoveNot = (e, index) => {
    setIndexPlace(index);
  };

  const closeIndex = () => {
    setIndexPlace('');
  };

  console.log('data source', dataSource);

  return (
    <PageContainer heading={dataID === '' ? 'Add Data Source' : 'Edit Data Source'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={12}>
              <TextField
                required
                label="Type Name"
                variant="outlined"
                name="typeName"
                type="text"
                value={dataSource.typeName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={3}>
              <div>
                <Dropzone onDrop={onDrop}>
                  {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
                    return (
                      <div>
                        {dataSource.logo ? (
                          dataSource.logo.path ? (
                            <img
                              src={URL.createObjectURL(dataSource.logo)}
                              className={classes.imgClass}
                              alt={dataSource.logo.name}
                            />
                          ) : dataSource.logo.substring(dataSource.logo.lastIndexOf('/') === '/ ') ? (
                            <img src={blankLogo} className={classes.imgClass} alt={dataSource.typeName} />
                          ) : (
                            <img src={dataSource.logo} className={classes.imgClass} alt={dataSource.typeName} />
                          )
                        ) : (
                          <div {...getRootProps()} className={classes.uploadImage}>
                            <input {...getInputProps()} />
                            <AddPhotoAlternateOutlined color="primary" />
                            <p>Upload Logo here</p>
                          </div>
                        )}
                        <div style={{ textAlign: 'center', marginTop: '5px' }}>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              setDataSource({
                                ...dataSource,
                                logo: '',
                              })
                            }>
                            {' '}
                            Reset Logo
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                </Dropzone>
              </div>
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                type="text"
                label="Documentation URL"
                variant="outlined"
                name="helpLink"
                value={dataSource.helpLink}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} className={classes.header}>
              <h3>User Defined Fields</h3>
            </Grid>

            <Grid container spacing={4} className={classes.box}>
              <Grid item sm={6} md={6}>
                {fieldDetails.udf.map((field, index) => {
                  return (
                    <div className={classes.addMoreUDF}>
                      <TextField
                        type="text"
                        label={`User Defined Field ${Math.ceil(index + 1)}`}
                        value={field}
                        onChange={e => handleChangeUDF(e, index)}
                        className={classes.text}
                        variant="outlined"
                      />
                    </div>
                  );
                })}
              </Grid>

              <Grid item sm={6} md={6}>
                {fieldDetails.encription.map((enc, index) => {
                  if (index < fieldDetails.udf.length) {
                    return (
                      <div className={classes.addMore} ref={ref}>
                        <FormControlLabel
                          className={classes.arrayField}
                          control={
                            <Checkbox checked={enc} onChange={e => handleEncription(e, index)} value={enc} color="primary" />
                          }
                          label="Encryption Required?"
                        />
                        {fieldDetails.udf.length > 1 && (
                          <Fragment>
                            <Tooltip title="Remove" className={classes.remove}>
                              <IconButton onClick={e => (dataID == '' ? handleRemove(e, index) : handleRemoveNot(e, index))}>
                                <Close color="primary" />
                              </IconButton>
                            </Tooltip>
                            {indexPlace === index && (
                              <Card className={classes.place}>
                                <div>Please contact admin to delete this UDF field</div>
                                <Cancel onClick={closeIndex} fontSize="small" style={{ cursor: 'pointer' }} />
                              </Card>
                            )}
                          </Fragment>
                        )}
                      </div>
                    );
                  }
                })}
              </Grid>
              <Grid item sm={12} className={classes.btnAddMore}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={e => handleAdd(e)}
                  style={{ marginLeft: '10px' }}
                  disabled={fieldDetails.udf.length > 4 ? true : false}>
                  Add More +
                </Button>
              </Grid>
            </Grid>

            <Grid item sm={12} md={12}>
              <TextField
                multiline
                rows={4}
                label="Integration Notes"
                variant="outlined"
                name="notes"
                type="text"
                value={dataSource.notes}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="typeActive"
                    checked={dataSource.typeActive}
                    onChange={handleCheck}
                    value={dataSource.typeActive}
                    color="primary"
                  />
                }
                label="Data Source Active?"
              />
            </Grid>

            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={loading ? true : false}>
                {dataID === '' ? 'Add Data Source' : 'Edit Data Source'}
              </Button>
              {loading && <CircularProgress size={25} style={{ marginLeft: '10px' }} />}
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        className={classes.snack}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {messageState === 'success' ? message : message.length > 0 && message.map(msg => <p>{msg}</p>)}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default AddDataSource;
